import { avatarClasses } from "@mui/material";
import styled from "styled-components";

export const DivTwoDivRowDivOne = styled.div`
  box-sizing: border-box;
  flex: 50 0 auto;
  min-width: 0px;
  width: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
`;


export const DivTwoDivRowDivOneDiv = styled.div`
  position: relative;
  box-sizing: inherit;
`;

export const DivTwo = styled.div`
  min-width: 0px;
  
  @media screen and (min-width: 768px) {
    display: flex;
  }

  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 0px 1px;
  border-radius: 8px 8px 0px 0px;
`;

export const DivTwoDiv = styled.div`
  @media screen and (min-width: 768px) {
    display: block;
  }

  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
  display: none;
  box-sizing: inherit;
`;


export const DivTwoDivRow = styled.div`
  display: flex;
  flex: 1 0 auto;
  min-width: 0px;
  height: 48px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px;
  box-sizing: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
`;


export const DivTwoDivRowDivTwo = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 24px;
  width: 25% !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
`;

export const DivTwoDivRowDivThree = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
`;

export const DivTwoDivRowDivFour = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
  font-family: Gotham, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
`;

export const DivTwoDivRowDivFive = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
`;

export const DivTwoDivRowDivSix = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
`;

export const DivTwoDivRowDivSeven = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
`;

export const DivTwoDivRowGroup = styled.div`
  width: 100%;
  font-weight: 400;
  color: rgb(105, 96, 87);
  box-sizing: inherit;
  &:hover {
    box-shadow: inset 0px 0px 0px 1px #A89968;
  }
`;

export const DivTwoDivRowGroupA = styled.a`
  display: flex;
  flex: 1 0 auto;
  min-width: 0px;
  height: 64px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px;
  transition: outline 0.3s ease 0s;
  will-change: box-shadow;
  text-decoration: none !important;
  color: unset;
  background-color: transparent;
  box-sizing: inherit;
  cursor: pointer;
  font-weight: 400;
`;

export const DivTwoDivRowGroupACellOne = styled.div`
  box-sizing: border-box;
  flex: 50 0 auto;
  min-width: 0px;
  width: 50px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 24px;
  font-size: 14px;
  line-height: 16px;
  color: unset;
`;

export const DivTwoDivRowGroupACellTwo = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 24px;
  font-size: 14px;
  line-height: 16px;
  width: 25% !important;
`;

export const DivTwoDivRowGroupACellTwoDiv = styled.div`
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  box-sizing: inherit;
  font-size: 14px;
  line-height: 16px;
`;

export const DivTwoDivRowGroupACellTwoDivImg = styled.img`
  flex: 0 0 auto;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  filter: invert(1);
  opacity: 0.7;
  transform: translateZ(0px);
  border-style: none;
  box-sizing: inherit;
  overflow-clip-margin: content-box;
  overflow: clip;
`;

export const DivTwoDivRowGroupACellTwoDivText = styled.div`
  margin-bottom: 5px;
  color: rgb(128, 126, 126);
  box-sizing: inherit;
`;

export const DivTwoDivRowGroupACellTwoDivSubText = styled.div`
  color: rgb(56, 46, 43);
  box-sizing: inherit;
`;


export const DivTwoDivRowGroupACell = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 24px;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none !important;
`;

export const DivTwoDivRowGroupACellLicense = styled.div`
  padding: 0px;
  font-weight: 700;
  color: rgb(56, 46, 43);
  box-sizing: inherit;
`;

export const DivTwoDivRowGroupACellLicenseImg = styled.img`
  color: rgb(56, 46, 43);
  width: 28px;
  height: 28px;
  margin-right: 8px;
  border-style: none;
  box-sizing: inherit;
  overflow-clip-margin: content-box;
  overflow: clip;
`;


export const DivTwoDivRowGroupACellSubscriptionDivOne = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-weight: 700;
  color: rgb(56, 46, 43);
  box-sizing: inherit;
`;

//SYSTEM STATES STYLES START

export const DivTwoDivRowDivOneSS = styled.div`
  box-sizing: border-box;
  flex: 50 0 auto;
  min-width: 0px;
  width: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 12px;
  font-weight: 400;
  font-size: 14px;
  color: rgb(105, 96, 87);
`;


export const DivTwoDivRowDivOneDivSS = styled.div`
  position: relative;
  box-sizing: inherit;
`;

export const DivTwoSS = styled.div`
  min-width: 0px;
  
  @media screen and (min-width: 768px) {
    display: flex;
  }

  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 0px 1px;
  border-radius: 8px 8px 0px 0px;
`;

export const DivTwoDivSS = styled.div`
  @media screen and (min-width: 768px) {
    display: block;
  }

  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: rgb(105, 96, 87);
  display: none;
  box-sizing: inherit;
`;


export const DivTwoDivRowSS = styled.div`
  display: flex;
  flex: 1 0 auto;
  min-width: 0px;
  height: 48px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px;
  box-sizing: inherit;
  font-weight: 400;
  font-size: 14px;
  color: rgb(105, 96, 87);
`;


export const DivTwoDivRowDivTwoSS = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 12px;
  width: 25% !important;
  font-weight: 400;
  font-size: 14px;
  color: rgb(105, 96, 87);
`;

export const DivTwoDivRowDivThreeSS = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 12px;
  font-weight: 400;
  font-size: 14px;
  color: rgb(105, 96, 87);
`;

export const DivTwoDivRowDivFourSS = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 12px;
  font-weight: 400;
  font-size: 14px;
  color: rgb(105, 96, 87);
  font-family: Gotham, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
`;

export const DivTwoDivRowDivFiveSS = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 12px;
  font-weight: 400;
  font-size: 14px;
  color: rgb(105, 96, 87);
`;

export const DivTwoDivRowDivSixSS = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 12px;
  font-weight: 400;
  font-size: 14px;
  color: rgb(105, 96, 87);
`;

export const DivTwoDivRowDivSevenSS = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 12px;
  font-weight: 400;
  font-size: 14px;
  color: rgb(105, 96, 87);
`;

export const DivTwoDivRowGroupSS = styled.div`
  width: 100%;
  font-weight: 400;
  color: rgb(105, 96, 87);
  box-sizing: inherit;
  &:hover {
    box-shadow: inset 0px 0px 0px 1px #A89968;
  }
`;

export const DivTwoDivRowGroupASS = styled.a`
  display: flex;
  flex: 1 0 auto;
  min-width: 0px;
  height: 64px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px;
  transition: outline 0.3s ease 0s;
  will-change: box-shadow;
  text-decoration: none !important;
  color: unset;
  background-color: transparent;
  box-sizing: inherit;
  cursor: pointer;
  font-weight: 400;

  &:hover {
    box-shadow: inset 0px 0px 0px 1px #A89968;
    color: #A89968;
  }
`;

export const DivTwoDivRowGroupACellOneSS = styled.div`
  box-sizing: border-box;
  flex: 50 0 auto;
  min-width: 0px;
  width: 50px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 12px;
  font-size: 14px;
  color: unset;
`;

export const DivTwoDivRowGroupACellTwoSS = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 12px;
  font-size: 14px;
  width: 25% !important;
`;

export const DivTwoDivRowGroupACellTwoDivSS = styled.div`
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  box-sizing: inherit;
  font-size: 14px;
`;

export const DivTwoDivRowGroupACellTwoDivImgSS = styled.img`
  flex: 0 0 auto;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  filter: invert(1);
  opacity: 0.7;
  transform: translateZ(0px);
  border-style: none;
  box-sizing: inherit;
  overflow-clip-margin: content-box;
  overflow: clip;
`;

export const DivTwoDivRowGroupACellTwoDivTextSS = styled.div`
  color: #696057;
  box-sizing: inherit;
  font-size: 14px;
  font-weight: 900;
`;

export const DivTwoDivRowGroupACellTwoDivSubTextSS = styled.div`
  color: rgb(56, 46, 43);
  box-sizing: inherit;
`;


export const DivTwoDivRowGroupACellSS = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 12px;
  font-size: 14px;
  text-decoration: none !important;
`;

export const DivTwoDivRowGroupACellLicenseSS = styled.div`
  padding: 0px;
  font-weight: 700;
  color: rgb(56, 46, 43);
  box-sizing: inherit;
`;

export const DivTwoDivRowGroupACellLicenseImgSS = styled.img`
  color: rgb(56, 46, 43);
  width: 28px;
  height: 28px;
  margin-right: 8px;
  border-style: none;
  box-sizing: inherit;
  overflow-clip-margin: content-box;
  overflow: clip;
`;


export const DivTwoDivRowGroupACellSubscriptionDivOneSS = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-weight: 700;
  color: rgb(56, 46, 43);
  box-sizing: inherit;
`;

export const DivTwoDivRowGroupACellTwoDivTextConfig = styled.div`
  color: #696057;
  box-sizing: inherit;
  font-size: 14px;
  font-weight: 600;
  margin: 0px 0px 0px 20px;
`;

export const DivTwoDivRowGroupACellConfigSvgDiv = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: right;
  height: 100%;
  padding: 24px;
  font-size: 14px;
  text-decoration: none !important;
`;

export const DivTwoDivRowGroupACellTwoConfig = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 24px;
  font-size: 14px;
  width: 25% !important;
`;

export const DivTwoDivRowGroupACellTwoDivTextSoftwareUpdate = styled.div`
  color: #696057;
  box-sizing: inherit;
  font-size: 14px;
  font-weight: 900;
  margin: 0px 0px 0px 20px;
`;

export const DivTwoDivRowDivThreeAppliedLicenses = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 111px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 12px;
  font-weight: 400;
  font-size: 12px;
  color: rgb(105, 96, 87);
`;

export const DivTwoDivRowGroupACellTwoDivTextAppliedLicenses = styled.div`
  display: flex;
  flex-direction: column;
  color: #696057;
  box-sizing: inherit;
  font-size: 12px;
  font-weight: 700;
`;

export const DivTwoDivRowGroupAppliedLicenses = styled.div`
  width: 100%;
  font-weight: 400;
  color: rgb(105, 96, 87);
  box-sizing: inherit;
  &:hover {
    box-shadow: inset 0px 0px 0px 1px #A89968;
    color: #A89968;
  }
`;

export const DivTwoDivRowGroupAAppliedLicenses = styled.a`
  display: flex;
  flex: 1 0 auto;
  min-width: 0px;
  height: 64px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px;
  transition: outline 0.3s ease 0s;
  will-change: box-shadow;
  text-decoration: none !important;
  color: unset;
  background-color: transparent;
  box-sizing: inherit;
  cursor: pointer;
  font-weight: 400;

  &:hover {
    box-shadow: inset 0px 0px 0px 1px #A89968;
    color: #A89968;
  }
`;

export const NameTwoAppliedLicenses = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none !important;
`;

export const DivTwoDivRowGroupACellTwoAppliedLicenses = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 12px 12px 12px 24px;
  font-size: 14px;
  width: 25% !important;
`;

export const DivTwoDivRowGroupACellLicenseCode = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 12px;
  font-size: 12px;
  text-decoration: none !important;
  width: 10% !important;
  word-break: break-all;
`;

export const DivTwoDivRowGroupACellOrderNumber = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 12px;
  font-size: 12px;
  text-decoration: none !important;
  width: 10% !important;
  word-break: break-all;
`;

export const DivTwoDivRowGroupACellPurchaseOrder = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 12px;
  font-size: 12px;
  text-decoration: none !important;
  width: 10% !important;
  word-break: break-all;
`;

export const DivTwoDivRowGroupACellActivated = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 12px;
  font-size: 14px;
  text-decoration: none !important;
  width: 10% !important;
  word-break: break-all;
`;

export const DivTwoDivRowGroupACellEnds = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 12px;
  font-size: 14px;
  text-decoration: none !important;
  width: 10% !important;
  word-break: break-all;
`;

export const DivTwoDivRowGroupACellNotes = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 12px;
  font-size: 14px;
  text-decoration: none !important;
  width: 10% !important;
  word-break: break-all;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  text-overflow: ellipsis;
`;

export const DivTwoDivRowGroupACellMarkerSvg = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 12px;
  font-size: 14px;
  text-decoration: none !important;
  width: 5% !important;
  overflow: hidden;
`;