import {Route, Routes} from 'react-router-dom';

import MultipleUpload from './pages/MultipleUpload';
import Login from './pages/Login';
import Homes from './pages/Homes';
import HomeStatus from './components/HomeItems/HomeStatus';
import Listing from './pages/Listing';
import Templates from './pages/Templates';
import Profiles from './pages/Profiles';
import Licenses from './pages/Licenses';
import Team from './pages/Team';
import { ControllerProvider } from './contexts/controller';

function App() {
  return (
    <ControllerProvider>
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/Login" element={<Login/>}/>
        <Route path="/Listing" element={<Listing/>}/>
        <Route path="/Upload" element={<MultipleUpload/>}/>
        <Route path="/Homes" element={<Homes/>}/>
        <Route path="/HomeStatus" element={<HomeStatus/>}/>
        <Route path="/Templates" element={<Templates/>}/>
        <Route path="/Profiles" element={<Profiles/>}/>
        <Route path="/Licenses" element={<Licenses/>}/>
        <Route path="/Team" element={<Team/>}/>
      </Routes>
    </ControllerProvider>
  );
}

export default App;