import styled from 'styled-components';
import Accordion from 'react-bootstrap/Accordion';

//color: "#A89968", background: "rgb(237, 234, 229)"

export const CustomAccordion = styled(Accordion)`
  background-color: #F8F8F8;
  box-shadow: none;
   &:last-child {
    border-bottom: 8px;
  }
  .accordion-button {
    background-color: #F8F8F8;
    border-color: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: none;
  }
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .accordion-item:last-of-type .accordion-button {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .accordion-button:not(.collapsed) {
    background-color: rgb(237, 234, 229);
    color: #A89968;
    box-shadow: none;
  }
  .accordion-button:focus {
    background-color: rgb(237, 234, 229);
    color: #A89968;
    box-shadow: none;
  }
`;

export const CustomAccordionItem = styled(Accordion.Item)`
  background-color: #F8F8F8;
  border-color: none;
  border-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #fff;
  background-color: #F8F8F8;
  border: none;
  transition: all 0.2s ease 0s;
  box-shadow: none;

  .accordion-button:hover {
    color: rgb(168, 153, 104);
    background-color: rgb(237, 234, 229);
    color: #A89968;
    box-shadow: none;
  }
  .accordion-button {
    background-color: #FFF;
    border-color: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin: 0px 0px 8px;
    padding: 12px 24px;
    box-shadow: none;
  }
  .accordion-button:not(.collapsed) {
    background-color: rgb(237, 234, 229);
    color: #A89968;
    box-shadow: none;
  }
  .accordion-button:focus {
    background-color: rgb(237, 234, 229);
    color: #A89968;
    box-shadow: none;
  }
`;

export const CustomAccordionHeader = styled(Accordion.Header)`
  align-items: center;
  background-color: #F8F8F8;
  border-radius: 8px;
  color: #000;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  justify-content: space-between;
  box-shadow: none;
  svg {
    height: 24px;
    width: 24px;
    color: rgb(105, 96, 87);
  }
  &:last-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &:click {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .accordion-button:not(.collapsed) {
    background-color: rgb(237, 234, 229);
    color: #A89968;
    box-shadow: none;
  }
  .accordion-button:focus {
    background-color: rgb(237, 234, 229);
    color: #A89968;
    box-shadow: none;
  }
  .accordion-button:hover {
    color: rgb(168, 153, 104);
    background-color: rgb(237, 234, 229);
    color: #A89968;
    box-shadow: none;
  }
`;

export const CustomAccordionBody = styled(Accordion.Body)`
  padding: 0;
  background-color: #F8F8F8;
  border: none;
  color: #F8F8F8;
  font-size: 14px;
  font-weight: 400;
`;

export const AccordionHeaderItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const SVGAndTitleDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TitlesDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 0px 0px 16px;
  gap: 4px;
  font-weight: 600;
  font-size: 16px;
`;

export const SubTitle = styled.p`
  font-weight: normal;
  font-size: 14px;
  color: rgb(128, 126, 126);
  margin: 0px;
`;

export const AvailableNumber = styled.div`
  font-weight: normal;
  font-size: 16px;
  font-weight: 600;
  color: rgb(56, 46, 43);
  margin: 0px 24px 0px 0px;
  vertical-align: middle;
  align-items: center;
  display: flex;
  flex-direction: row;
`;