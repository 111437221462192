import React, { useState } from "react";

import { 
  Main,
  OuterDiv,
  MainContainer,
  TopGroup,
  TopGroupNav,
  TopGroupNavDivOne,
  TopGroupNavDivOneItemOne,
  TopGroupNavDivOneItemA,
  TopGroupNavDivOneItemTwo,
  TopGroupNavDivOneItemTwoSpan,
  TopGroupNavDivOneItemTwoDiv,
  TopGroupNavDivOneItemTwoDivOne,
  TopGroupNavDivOneItemTwoDivOneDivOne,
  TopGroupNavDivOneItemTwoDivOneDivTwo,
  TopGroupNavDivOneItemTwoDivOneDivTwoInput,
  TopGroupNavDivOneItemTwoDivTwo,
  TopGroupNavDivOneItemTwoDivTwoSpan,
  TopGroupNavDivOneItemTwoDivTwoDiv,
} from "./stylesTopBar";

import {
  InnerHeader,
  InnerHeaderDivOne,
  InnerHeaderDivOneH4,
  InnerHeaderDivTwo,
  InnerHeaderDivThree,
  InnerHeaderDivThreeButton,
  InnerHeaderDivThreeButtonSpanOne,
  InnerHeaderDivThreeButtonSpanTwo,
} from "./stylesHeader";

import {
  BodyDiv,
  BodyDivDiv,
  BodyDivDivOne,
  BodyDivDivOneDiv,
  BodyDivDivOneDivOne,
} from "./stylesBody";

import Header from "../../components/Header";
import Search from "../../components/Search";
import List from "../../components/Lists";
import Buttons from "../../components/Buttons";

import { useNavigate } from 'react-router-dom';

export default function Listing() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/Homes');
  }

  return (
    <>
      <Header login={false}/>
      <Main>
        <OuterDiv>
          <MainContainer>
            <OuterDiv>
              <TopGroup>
                <TopGroupNav>
                  <TopGroupNavDivOne>
                    <TopGroupNavDivOneItemOne>
                      <TopGroupNavDivOneItemA href="/Homes">
                        <svg viewBox="0 0 24 24" role="presentation" aria-hidden="true" focusable="false" style={{height: "1.5rem", width: "1.5rem"}}>
                          <path d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                            fill="rgb(187, 168, 140)"
                          ></path>
                        </svg>
                        <span
                          style={{
                            display: "inline",
                            marginLeft: "16px",
                            fontSize: "14px",
                            fontWeight: 600,
                            boxSizing: "inherit",
                          }}
                        >/</span>
                      </TopGroupNavDivOneItemA>
                    </TopGroupNavDivOneItemOne>
                    <TopGroupNavDivOneItemTwo>
                      <TopGroupNavDivOneItemTwoSpan/>
                      <TopGroupNavDivOneItemTwoSpan/>
                      <TopGroupNavDivOneItemTwoDiv>
                        <TopGroupNavDivOneItemTwoDivOne>
                          <TopGroupNavDivOneItemTwoDivOneDivOne>
                            Select Group
                          </TopGroupNavDivOneItemTwoDivOneDivOne>
                          <TopGroupNavDivOneItemTwoDivOneDivTwo>
                            <TopGroupNavDivOneItemTwoDivOneDivTwoInput/>
                          </TopGroupNavDivOneItemTwoDivOneDivTwo>
                        </TopGroupNavDivOneItemTwoDivOne>
                        <TopGroupNavDivOneItemTwoDivTwo>
                          <TopGroupNavDivOneItemTwoDivTwoSpan/>
                          <TopGroupNavDivOneItemTwoDivTwoDiv>
                            <svg viewBox="0 0 20 20" aria-hidden="true" focusable="false" style={{height: "20", width: "20"}}>
                              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                                fill="rgb(187, 168, 140)"
                              ></path>
                            </svg>
                          </TopGroupNavDivOneItemTwoDivTwoDiv>
                        </TopGroupNavDivOneItemTwoDivTwo>
                      </TopGroupNavDivOneItemTwoDiv>
                    </TopGroupNavDivOneItemTwo>
                  </TopGroupNavDivOne>
                </TopGroupNav>
                <Search text={"Search Homes"}/>
              </TopGroup>
              <InnerHeader id='header'>
                <InnerHeaderDivOne id='div1'>
                  <InnerHeaderDivOneH4>
                    Roberto Adams
                  </InnerHeaderDivOneH4>
                </InnerHeaderDivOne>
                <InnerHeaderDivTwo id='div2'>
                  <Buttons quantity={2} titles={["Homes", "Pending Jobs"]} />
                </InnerHeaderDivTwo>
                <InnerHeaderDivThree id='div3'>
                  <InnerHeaderDivThreeButton>
                    <svg viewBox="0 0 24 24" role="presentation" style={{height: "1.5rem", width: "1.5rem"}}>
                    <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                      fill="rgb(187, 168, 140)"
                    ></path>
                    </svg>
                    <InnerHeaderDivThreeButtonSpanOne>
                      Add
                    </InnerHeaderDivThreeButtonSpanOne>
                    <InnerHeaderDivThreeButtonSpanTwo>
                      <svg viewBox="0 0 24 24" role="presentation" style={{height: "1.5rem", width: "1.5rem"}}>
                      <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
                        fill="rgb(187, 168, 140)"
                      ></path>
                      </svg>
                    </InnerHeaderDivThreeButtonSpanTwo>
                  </InnerHeaderDivThreeButton>
                </InnerHeaderDivThree>
              </InnerHeader>
              <BodyDiv>
                <BodyDivDiv>
                  <BodyDivDivOne id='this is div two, div one has display none'>
                    <BodyDivDivOneDiv>
                      <BodyDivDivOneDivOne/>
                      <List listType="listing"/>
                    </BodyDivDivOneDiv>
                  </BodyDivDivOne>
                </BodyDivDiv>
              </BodyDiv>
            </OuterDiv>
          </MainContainer>
        </OuterDiv>
      </Main>
    </>
  );
}