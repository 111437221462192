import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (min-width: 1024px) {
    display: flex;
    height: 24px;
    margin: 16px 24px;
  }

  display: none;
  box-sizing: inherit;
`;

export const DivOne = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  box-sizing: inherit;
`;

export const Span = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: inherit;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
`;

export const DivTwo = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  box-sizing: inherit;
`;

export const Before = styled.div`
  content: "/";
  margin: 0px 16px;
`;

export const DivThree = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  box-sizing: inherit;
  content: "/";
  margin: 0px 16px;
`;

export const DivFour = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  box-sizing: inherit;
`;

export const DivFourDiv = styled.div`
  margin: 0px 8px 0px 12px;
  box-sizing: inherit;
`;

export const DivFourDivImgDiv = styled.div`
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  box-sizing: inherit;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
`;

export const DivFourDivImg = styled.img`
  flex: 0 0 auto;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  filter: invert(1);
  opacity: 0.7;
  transform: translateZ(0px);
  border-style: none;
  box-sizing: inherit;
  overflow-clip-margin: content-box;
  overflow: clip;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
`;
