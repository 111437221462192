import {
  Content,
  Header,
  HeaderTitle,
  HeaderTitleImgDiv,
  HeaderTitleName,
  HeaderDescription,
  HeaderDescriptionDiv,
  HeaderDescriptionP,
  HeaderDescriptionButton,
  ContentContent,
  RoehnEssencialsDiv,
  RoehnEssencialsTitle,
  ManageStatusDiv,
  ManageStatusContent,
  ManageStatusDescription,
  ChecksDiv,
  MainContainer,
  SectionOneSubtitle,
  SubscriptionHistoryDiv,
  SubscriptionHistoryInfoText,
  SubscriptionHistoryTitle,
  DividerDiv,
  SubscriptionHouseImage,
  ContentHouseImage,
  ExpirationText,
  ExpirationContainer,
  ManageContent,
  SubscriptionAndEssentialsDiv,
} from "./styles";

import Buttons from "../../Buttons";

import subscriptionHouse from "../../../assets/subscriptionHouse.svg";
import noSubscriptionHistory from "../../../assets/noSubscriptionHistory.svg";
import subscriptionCheck from "../../../assets/subscriptionCheck.svg";

export default function Subscriptions() {
  return (
    <>
      <MainContainer>
        <Content>
          <SubscriptionAndEssentialsDiv>
          <Header>
            <HeaderTitle>
              <HeaderTitleName>Subscription Status</HeaderTitleName>
            </HeaderTitle>
            <HeaderDescription>
              <HeaderDescriptionDiv>
                <HeaderDescriptionP> More Details </HeaderDescriptionP>
              </HeaderDescriptionDiv>
              <HeaderDescriptionButton>
              </HeaderDescriptionButton>
            </HeaderDescription>
          </Header>
          <ContentContent>
            <RoehnEssencialsDiv>
              <HeaderTitleImgDiv>
                <img src={subscriptionCheck} alt="subscriptionCheck" />
              </HeaderTitleImgDiv>
              <RoehnEssencialsTitle>
                Roehn Essentials Active
              </RoehnEssencialsTitle>
            </RoehnEssencialsDiv>
          </ContentContent>
          </SubscriptionAndEssentialsDiv>
          <ManageContent>
            <ManageStatusDiv>
              <ManageStatusContent>
                <ManageStatusDescription>
                  <Buttons quantity={1} titles={['Manage']} />
                </ManageStatusDescription>
              </ManageStatusContent>
              <ChecksDiv>
              <svg
                viewBox="0 0 24 24"
                role="presentation"
                style={{ width: '1.5rem', height: '1.5rem' }}>
                <path
                  d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z"
                  style={{ fill: 'rgb(255, 95, 0)' }}>
                </path>
              </svg>
            </ChecksDiv>
            </ManageStatusDiv>
            <ExpirationContainer>
              <ExpirationText>Expiration Date</ExpirationText>
              <ExpirationText>03/03/2024</ExpirationText>
            </ExpirationContainer>
          </ManageContent>
        </Content>
        <DividerDiv />
        <ContentHouseImage>
          <SubscriptionHouseImage src={subscriptionHouse} alt="subscriptionHouse" />
        </ContentHouseImage>
      </MainContainer>
      <SectionOneSubtitle>Subscription History</SectionOneSubtitle>
      <SubscriptionHistoryDiv>
        <img src={noSubscriptionHistory} alt="noSubscriptionHistory" />
        <SubscriptionHistoryTitle>No subscription history yet</SubscriptionHistoryTitle>
        <SubscriptionHistoryInfoText>Only subscriptions purchased through Roehn Central Management will</SubscriptionHistoryInfoText>
        <SubscriptionHistoryInfoText>show history</SubscriptionHistoryInfoText>
      </SubscriptionHistoryDiv>
    </>
  )
}

