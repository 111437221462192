import { useState } from 'react'

import { SectionOneContent, SectionOneTitle, SectionOneSubtitle, AvailableUpdatesDiv, AvailableUpdatesInfoText, SectionOneSubtitle2, SectionOneSubtitle2Div, CurrentVersionSideContainer, AvailableUpdatesFilesDiv, CurrentVersionMainContainer } from './styles'
import Buttons from '../../Buttons';
import Lists from '../../Lists';

export default function SoftwareUpdate() {
  return (
    <SectionOneContent>
      <SectionOneTitle>Software Update</SectionOneTitle>
      <CurrentVersionMainContainer>
        <SectionOneSubtitle2>Current Version</SectionOneSubtitle2>
        <SectionOneSubtitle2Div>
          <svg viewBox="0 0 24 24" role="presentation" style={{ width: '1.5rem', height: '1.5rem' }}>
            <path
              d="M14.6,16.6L19.2,12L14.6,7.4L16,6L22,12L16,18L14.6,16.6M9.4,16.6L4.8,12L9.4,7.4L8,6L2,12L8,18L9.4,16.6Z"
              style={{ fill: 'rgb(210, 204, 193)' }}>
            </path>
          </svg>
          <CurrentVersionSideContainer>da Vinci 10.2.0-cprd1-pro:619</CurrentVersionSideContainer>
        </SectionOneSubtitle2Div>
      </CurrentVersionMainContainer>
      <AvailableUpdatesFilesDiv>
        <SectionOneSubtitle>Available Updates</SectionOneSubtitle>
        <Buttons quantity={2} titles={['Available Updates', 'Previous Versions']} />
      </AvailableUpdatesFilesDiv>
      <Lists listType='softwareUpdate' />
      <AvailableUpdatesFilesDiv>
        <SectionOneSubtitle>Update History</SectionOneSubtitle>
        <Buttons quantity={2} titles={['All Updates', 'Installed']} />
      </AvailableUpdatesFilesDiv>
      <AvailableUpdatesDiv>
        <AvailableUpdatesInfoText>No history available</AvailableUpdatesInfoText>
      </AvailableUpdatesDiv>
    </SectionOneContent>
  )
}
