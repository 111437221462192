import styled from 'styled-components'

export const SectionOneContent = styled.div`
  @media screen and (min-width: 768px) {
    margin-bottom: 16px;
  }

    width: 100%;
    margin-bottom: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    padding: 16px 24px 24px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.01) 10px 10px 10px;
    animation: 0.2s ease-out 0s 1 normal none running krkVAV;
    box-sizing: inherit;
`;

export const SectionOneTitle = styled.h2`
  font-size: 32px;
  line-height: 32px;
  color: rgb(0, 0, 0);
  margin-bottom: 8px;
  color: rgb(56, 46, 43);
`;

export const SectionOneSubtitle = styled.h2`
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 600;
  color: rgb(56, 46, 43);
`;

export const SectionOneSubtitle2Div = styled.div`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;
    background: rgb(242, 242, 242);
    border-radius: 8px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 900;
`;


export const SectionOneSubtitle2 = styled.h2`
    display: block;
    font-size: 1em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 600;
    color: rgb(56, 46, 43);
    margin-top: 16px;
`;

export const CurrentVersionMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 20px 0px
`;


export const CurrentVersionSideContainer = styled.div`
    display: block;
    font-size: 1em;
    font-weight: 600;
    color: rgb(56, 46, 43);
    text-vertical-align: middle;
`;

export const AvailableUpdatesDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  width: 1080px;
  height: 100px;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
`;

export const AvailableUpdatesInfoText = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #A5A5A5;
  margin: 0 auto;
  vertical-align: middle;
`;

export const AvailableUpdatesFilesDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

