import { SectionOneContent, SectionOneTitle, SectionOneSubtitle, LogsDiv, AppliedLicensesDiv, AppliedLicensesText } from './styles'
import Lists from '../../Lists';
import Buttons from '../../Buttons';
import DropdownList from '../../DropdownList';

export default function Logs() {
  return (
    <SectionOneContent>
      <LogsDiv>
        <SectionOneTitle>Logs</SectionOneTitle>
        <Buttons quantity={2} titles={['Refresh', 'Get Logs']} />
      </LogsDiv>
    </SectionOneContent>
  )
}