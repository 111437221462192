import { useState } from 'react'

import { SectionOneContent, SectionOneTitle, SectionOneSubtitle, LicensingDiv, AppliedLicensesDiv, AppliedLicensesText } from './styles'
import Lists from '../../Lists';
import Buttons from '../../Buttons';
import DropdownList from '../../DropdownList';

export default function Licensing() {
  return (
    <SectionOneContent>
      <LicensingDiv>
        <SectionOneTitle>Licensing</SectionOneTitle>
        <Buttons quantity={1} titles={['Visit Store']} />
      </LicensingDiv>
      <AppliedLicensesDiv>
        <SectionOneSubtitle>Applied Licenses</SectionOneSubtitle>
        <AppliedLicensesText>3 items</AppliedLicensesText>
      </AppliedLicensesDiv>
      <Lists listType='appliedLicenses' />
      <SectionOneSubtitle style={{marginTop: '40px', marginBottom: '16px'}}>Available Licenses</SectionOneSubtitle>
      <DropdownList />
    </SectionOneContent>
  )
}
