import styled from 'styled-components';

export const Container = styled.div`
    padding-top: 64px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px;
    background: linear-gradient(0deg, rgba(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
    display: flex;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: inherit;
    color: rgb(56, 46, 43);
    font-family: "Gotham", "Roboto", sans-serif;
    z-index: 1;
`;


export const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: inherit;
    font-family: "Gotham", "Roboto", sans-serif;
`;

export const LeftContainerItemOne = styled.div`
    margin-bottom: 8px;
    font-weight: bold;
    box-sizing: inherit;
    display: block;
    font-family: "Gotham", "Roboto", sans-serif;
`;

export const LeftContainerItemTwo = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: rgb(56, 46, 43);
    font-family: "Gotham", "Roboto", sans-serif;
`;

export const RightContainer = styled.div`
    grid-template-columns: auto auto auto;
    margin-top: 24px;
    display: grid;
    column-gap: 70px;
    box-sizing: inherit;
    font-family: "Gotham", "Roboto", sans-serif;
`;

export const Item = styled.a`
    font-size: 14px;
    padding-bottom: 8px;
    color: rgb(105, 96, 87);
    text-decoration: none;
    display: list-item;
    list-style-position: inside;
    background-color: transparent;
    box-sizing: inherit;
    cursor: pointer;
    
    &:hover {
        color: rgb(168, 153, 104);
    }
`;


