import styled from 'styled-components';

export const AsideContainer = styled.div`

  @media screen and (min-width: 768px) {
    background-color: transparent;
    box-shadow: none;
    overflow: visible;
    left: auto;
    display: block;
    width: 256px;
  }

  z-index: 96;
  display: flex;
  position: fixed;
  transition: all 0.3s ease 0s;
  left: 0px;
  top: 64px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 40px;
  overflow: auto;
  background-color: rgb(255, 255, 255);
  box-sizing: inherit;
`;


export const AsideTopDiv = styled.div`

  @media screen and (min-width: 768px) {
    background: inherit;
    padding: 16px 24px;
  }

  -webkit-box-align: center;
  align-items: center;
  display: flex;
  transition: all 0.2s ease 0s;
  background: rgb(255, 255, 255);
  box-sizing: inherit;
`;

export const AsideTopDivButton = styled.button`

  @media screen and (min-width: 768px) {
    padding: 0px;
  }

  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 900;
  line-height: 24px;
  color: #A89968;
  padding: 16px;
  font-weight: bold;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0px 6px;
`;

export const AsideTopDivSpan = styled.span`

  @media screen and (min-width: 1280px) {
    display: flex;
  }
  padding-left: 20px;
  white-space: nowrap;
  animation-name: krkVAV;
  animation-duration: 0.2s;
  box-sizing: inherit;
  font-size: 14px;
  font-weight: 900;
  line-height: 24px;
  color: #A89968;
  cursor: pointer;
  text-transform: none;
`;

export const AsideUL = styled.ul`

  @media screen and (min-width: 768px) {
    border-radius: 8px;
    flex-direction: column;
  }

  
  display: flex;
  flex-direction: row;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.01) 10px 10px 10px;
  border-radius: 0px;
  margin: 0px;
  padding: 0px;
  overflow: auto;
  max-height: 80vh;
  
  box-sizing: inherit;

  // display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  // padding-inline-start: 40px;
`;

export const AsideLI = styled.li`

  @media screen and (min-width: 768px) {
    flex-direction: row;
    width: auto;
    height: 56px;
  }

  
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 96px;
  height: 64px;
  list-style-type: none;
  padding: 0px;
  color: rgb(105, 96, 87);
  background: rgb(255, 255, 255);
  transition: all 0.2s ease 0s;
  box-sizing: inherit;
  text-align: -webkit-match-parent;
`;

export const AsideA = styled.a`

  @media screen and (min-width: 768px) {
    padding: 16px 24px;
    flex-direction: row;
    -webkit-box-pack: start;
    justify-content: flex-start;
    align-items: flex-start;
  }

  
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 8px;
  text-decoration: none !important;
  color: inherit;
  transition: all 0.2s ease 0s;
  width: 100%;
  
  background-color: transparent;
  
  box-sizing: inherit;
  
  cursor: pointer;

  &:hover {
    color:#A89968;
    transition: all 0.2s ease 0s;
  }

`;

export const AsideASpan = styled.span`

  @media screen and (min-width: 768px) {
    text-align: left;
    white-space: nowrap;
    font-size: 14px;
    line-height: 24px;
    margin-left: 20px;
  }
  
  flex: 1 1 0%;
  text-align: center;
  animation-name: krkVAV;
  animation-duration: 0.2s;
  font-weight: 900;
  font-size: 11px;
  line-height: 13px;
  margin-left: 0px;
  
  box-sizing: inherit;
  color: inherit;
  cursor: pointer;
  list-style-type: none;
`;
