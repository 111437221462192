import styled from 'styled-components';

export const SectionOneContent = styled.div`
  @media screen and (min-width: 768px) {
    margin-bottom: 16px;
  }

    width: 100%;
    margin-bottom: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    padding: 16px 24px 24px;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.01) 10px 10px 10px;
    animation: 0.2s ease-out 0s 1 normal none running krkVAV;
    box-sizing: inherit;
`;

export const SectionOneHeader = styled.div`
  @media screen and (min-width: 768px) {
    margin-bottom: 28px;
  }
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 12px;
    box-sizing: inherit;
`;

export const SectionOneHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: inherit;
`;

export const SectionOneHeaderTitleImgDiv = styled.div`
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  box-sizing: inherit;
`;

export const SectionOneHeaderTitleImg = styled.img`
  flex: 0 0 auto;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  filter: invert(1);
  opacity: 0.7;
  transform: translateZ(0px);
  border-style: none;
  box-sizing: inherit;
  overflow-clip-margin: content-box;
  overflow: clip;
`;

export const SectionOneHeaderTitleName = styled.div`
  @media screen and (min-width: 768px) {
    display: block;
    word-break: break-word;
  }
  
  display: none;
  font-size: 32px;
  line-height: 32px;
  margin-left: 24px;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: rgb(69, 68, 67);
  background: rgb(244, 252, 243);
  box-sizing: inherit;
`;

export const SectionOneHeaderDescription = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: inherit;
`;

export const SectionOneHeaderDescriptionDiv = styled.div`
  display: flex;
  height: 40px;
  padding: 13px 16px;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(56, 46, 43);
  border-radius: 8px;
  // background: rgba(219, 84, 42, 0.1);
  
  background: rgb(244, 252, 243);
  font-size: 14px;
  line-height: 16px;
  box-sizing: inherit;
`;

export const SectionOneHeaderDescriptionP = styled.p`
  box-sizing: inherit;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: rgb(56, 46, 43);
  font-size: 14px;
  line-height: 16px;
`;

export const SectionOneHeaderDescriptionButton = styled.button`
  padding: 0px;
  color: rgb(255, 95, 0);
  font-weight: bold;
  border: none;
  background-color: transparent;
  cursor: pointer;
  appearance: button;
  text-transform: none;
  overflow: visible;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0px;
  box-sizing: inherit;
`;

export const SectionOneSection = styled.section`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  box-sizing: inherit;
`;

export const SectionOneContentContent = styled.div`
  @media screen and (min-width: 768px) {
    gap: 32px 0px;
    flex-direction: row;
  }
  
  display: flex;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  box-sizing: inherit;
`;

export const HomeUIDDiv = styled.div`
  @media screen and (min-width: 1025px) {
    flex-basis: 32%;
    min-height: 64px;
  }

  @media screen and (min-width: 768px) {
    // flex-basis: 100%;
  }

  display: flex;
  flex-direction: column;
  box-sizing: inherit;
`;

export const HomeUIDTitle = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 16px;
  color: rgb(128, 126, 126);
  font-family: Gotham, Roboto, sans-serif;
  box-sizing: inherit;
`;

export const HomeUIDContent = styled.div`
  background: rgb(249, 249, 249);
  border-radius: 8px;
  padding: 8px 16px 4px;
  min-width: 240px;
  width: max-content;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: inherit;
`;

export const HomeUIDContentDescription = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: rgb(69, 68, 67);
  box-sizing: inherit;
`;

export const HomeUIDContentButton = styled.button`
  padding: 0px;
  color: rgb(255, 95, 0);
  font-weight: bold;
  border: none;
  background-color: transparent;
  cursor: pointer;
  appearance: button;
  text-transform: none;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0px;
  box-sizing: inherit;
`;


export const SystemAcessDiv = styled.div`
  @media screen and (min-width: 1025px) {
    flex-basis: 33.33%;
    min-height: 64px;
  }

  @media screen and (min-width: 768px) {
    // flex-basis: 50%;
  }

  display: flex;
  flex-direction: column;
  box-sizing: inherit;
`;

export const SystemAcessTitle = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 16px;
  color: rgb(128, 126, 126);
  font-family: Gotham, Roboto, sans-serif;
  box-sizing: inherit;
`;

export const SystemAcessContent = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: rgb(69, 68, 67);
  box-sizing: inherit;
`;

export const SystemAcessContentDescription = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  background-color: rgb(244, 252, 243);
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  padding: 11px 23px;
  width: max-content;
  box-sizing: inherit;
`;


export const MonitoringAcessDiv = styled.div`
  @media screen and (min-width: 1025px) {
    flex-basis: 33.33%;
    min-height: 64px;
  }

  @media screen and (min-width: 768px) {
    // flex-basis: 50%;
  }

  display: flex;
  flex-direction: column;
  box-sizing: inherit;
`;

export const MonitoringAcessTitle = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 16px;
  color: rgb(128, 126, 126);
  font-family: Gotham, Roboto, sans-serif;
  box-sizing: inherit;
`;

export const MonitoringAcessContent = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: rgb(69, 68, 67);
  box-sizing: inherit;
`;

export const MonitoringAcessDescription = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  background-color: rgb(244, 252, 243);
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  padding: 11px 23px;
  width: max-content;
  box-sizing: inherit;
`;

export const OverallSystemStatusDiv = styled.div`
  @media screen and (min-width: 1025px) {
    flex-basis: 19%;
  }

  @media screen and (min-width: 768px) {
    min-height: 96px;
    padding: 16px;
  }

  background-color: rgb(244, 252, 243);
  border-radius: 8px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: inherit;
`;

export const OverallSystemStatusContent = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 100%;
  box-sizing: inherit;
`;

export const OverallSystemStatusTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: rgb(105, 96, 87);
  white-space: nowrap;
  box-sizing: inherit;
`;

export const OverallSystemStatusDescription = styled.div`
  font-size: 32px;
  min-height: 42px;
  display: flex;
  align-items: flex-end;
  line-height: 0.7;
  color: rgb(35, 197, 20);
  font-weight: 600;
  box-sizing: inherit;
`;

export const ChecksDiv = styled.div`
  @media screen and (min-width: 1025px) {
    flex-basis: unset;
    min-width: 170px;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    // flex-basis: 50%;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: flex-start;
  }

  gap: 8px;
  flex-direction: column;
  font-size: 12px;
  box-sizing: inherit;
`;

export const ChecksContent = styled.div`
  display: flex;
  min-height: 16.8px;
  box-sizing: inherit;
`;

export const ChecksIconDiv = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 16px;
  box-sizing: inherit;
`;

export const ChecksDescription = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: inherit;
  font-size: 12px;
  color: rgb(56, 46, 43);
  font-family: Gotham, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
`;

export const DashboardDiv = styled.div`
  @media screen and (min-width: 1025px) {
    flex-basis: 19%;
  }

  @media screen and (min-width: 768px) {
    min-height: 96px;
    padding: 16px;
  }

  // background-color: rgb(244, 252, 243);
  background-color: rgb(249, 249, 249);
  border-radius: 8px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: inherit;
  color: #F9F9F9;
  width: 32%;
`;

export const DashboardContent = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 100%;
  box-sizing: inherit;
`;

export const DashboardTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: rgb(105, 96, 87);
  white-space: nowrap;
  box-sizing: inherit;
`;

export const DashboardValue = styled.div`
  font-size: 32px;
  min-height: 42px;
  display: flex;
  align-items: flex-end;
  line-height: 0.7;
  color: rgb(105, 96, 87);
  font-weight: 600;
  box-sizing: inherit;
  color: #696057;
`;

export const DashboardValueSpan = styled.span`
  font-size: 16px;
  box-sizing: inherit;
  line-height: 0.7;
  color: rgb(105, 96, 87);
  font-weight: 600;
`;

export const DashboardIconDiv = styled.div`
  box-sizing: inherit;
`;

export const DashboardIconDivContent = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  box-sizing: inherit;
`;

export const DashboardIcon = styled.div`
  @media screen and (min-width: 768px) {
    height: 48px;
    width: 48px;
  }

  box-sizing: inherit;
`;

