import { useState } from 'react'
import axios from 'axios'
import { SectionOneContent } from './styles'
import Search from '../../Search'
import List from '../../Lists'

export default function SystemStates() {
  return (
    <>
      <h1>System States</h1>
      <Search text="Search system state"/>
      <List listType="systemStates" />
      {/* <SectionOneContent>
      </SectionOneContent> */}
    </>
  )
}
