import { useNavigate } from 'react-router-dom';

import logo_header from '../../assets/logo_header.png'

import { 
  CustomNav,
  CustomNavbar,
  CustomContainer,
  ItemA,
  ItemDiv,
  ItemSelected,
  UserDiv,
  UserButton,
  ButtonDivOne,
  ButtonDivTwo,
  CustomNavbarBrand,
  LogoDescription,
  LogoDiv,
} from './styles';
import { useState } from 'react';

function Header(props: any) {
  const navigate = useNavigate();
  const [selectedHeaderItem, setSelectedHeaderItem] = useState('');

  const HandleHover = (item: string) => {
    setSelectedHeaderItem(item);
  }

  const HandleLeave = () => {
    setSelectedHeaderItem('');
  }

  const handleGoToLogin = () => {
    navigate('/Login');
  }

  const handleTemplates = () => {
    navigate('/Templates');
  }

  const handleProfiles = () => {
    navigate('/Profiles');
  }

  const handleLicenses = () => {
    navigate('/Licenses');
  }

  const handleTeam = () => {
    navigate('/Team');
  }

  const handleList = () => {
    navigate('/Listing');
  }

  return (
    <CustomNavbar bg="dark" variant="dark">
      <CustomContainer style={ props.login ? {maxWidth:'100%'} : {maxWidth:'1440px'}}>
        <CustomNavbarBrand onClick={handleGoToLogin}>
          <LogoDiv>
            <img
              alt=""
              src={logo_header}
              height="27.5"
              className="d-inline-block align-top"
            />
          </LogoDiv>
          <LogoDescription>
            Central Management
          </LogoDescription>
        </CustomNavbarBrand>
        {props.login ? (
          null
        ) : (
          <>
          <CustomNav>
            <ItemA onMouseOver={() => HandleHover('jobsHomes')} onMouseLeave={HandleLeave} onClick={handleList}>
              <ItemDiv onMouseOver={() => HandleHover('jobsHomes')}>
                Jobs & Homes
              </ItemDiv>
              {selectedHeaderItem === 'jobsHomes' ? <ItemSelected /> : null}
            </ItemA>
            <ItemA onMouseOver={() => HandleHover('templates')} onMouseLeave={HandleLeave} onClick={handleTemplates}>
              <ItemDiv onMouseOver={() => HandleHover('templates')}>
                Templates
              </ItemDiv>
              {selectedHeaderItem === 'templates' ? <ItemSelected /> : null}
            </ItemA>
            <ItemA onMouseOver={() => HandleHover('profiles')} onMouseLeave={HandleLeave} onClick={handleProfiles}>
              <ItemDiv onMouseOver={() => HandleHover('profiles')}>
                Profiles
              </ItemDiv>
              {selectedHeaderItem === 'profiles' ? <ItemSelected /> : null}
            </ItemA>
            <ItemA onMouseOver={() => HandleHover('licenses')} onMouseLeave={HandleLeave} onClick={handleLicenses}>
              <ItemDiv onMouseOver={() => HandleHover('licenses')}>
                Licenses
              </ItemDiv>
              {selectedHeaderItem === 'licenses' ? <ItemSelected /> : null}
            </ItemA>
            <ItemA onMouseOver={() => HandleHover('team')} onMouseLeave={HandleLeave} onClick={handleTeam}>
              <ItemDiv onMouseOver={() => HandleHover('team')}>
                Team
              </ItemDiv>
              {selectedHeaderItem === 'team' ? <ItemSelected /> : null}
            </ItemA>
          </CustomNav>
          <UserDiv>
            <div style={{ boxSizing:'inherit'}}>
              <UserButton>
                <ButtonDivOne>
                  <svg viewBox="0 0 24 24" style={{height: '24px', width: '24px'}}>
                    <path d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z"
                      fill="rgb(242, 242, 242)">
                    </path>
                  </svg>
                </ButtonDivOne>
                <ButtonDivTwo>
                  Roberto Adams
                </ButtonDivTwo>
                <svg viewBox="0 0 24 24" style={{height: '1.5rem', width: '1.5rem'}}>
                  <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
                    fill="rgb(242, 242, 242)">
                  </path>
                </svg>
              </UserButton>
            </div>
          </UserDiv>
          </>
        )}
      </CustomContainer>
    </CustomNavbar>
  )
}
export default Header;