import { Container, LeftContainer, RightContainer, Item, LeftContainerItemOne, LeftContainerItemTwo } from './styles'

function Footer() {
  return (
    <Container>
      <LeftContainer>
        <LeftContainerItemOne>Roehn Central Management, v1.0.0</LeftContainerItemOne>
        <LeftContainerItemTwo>© 2023 Roehn</LeftContainerItemTwo>
      </LeftContainer>
      <RightContainer>
        <Item>Integrator Community</Item>
        <Item>Store</Item>
        <Item>Roehn App</Item>
        <Item>Roehn.com</Item>
        <Item>Terms of Service</Item>
        <Item>Privacy Policy</Item>
      </RightContainer>
    </Container>
  )
}

export default Footer