import React, { useState } from "react";

import Header from "../../components/Header";

import HomeStatus from "../../components/HomeItems/HomeStatus";
import Configuration from "../../components/HomeItems/Configuration";
import SoftwareUpdate from "../../components/HomeItems/SoftwareUpdate";
import Subscriptions from "../../components/HomeItems/Subscriptions";
import Licensing from "../../components/HomeItems/Licensing";
import ConfiguredDevices from "../../components/HomeItems/ConfiguredDevices";
import SmartNetwork from "../../components/HomeItems/SmartNetwork";
import HostProcess from "../../components/HomeItems/HostProcess";
import SystemStates from "../../components/HomeItems/SystemStates";
import Services from "../../components/HomeItems/Services";
import Logs from "../../components/HomeItems/Logs";
import JobsInfo from "../../components/HomeItems/JobsInfo";

import { SideNav } from "../../components/SideNav";
import { TopPath } from "../../components/TopPath";

import { useNavigate } from 'react-router-dom';

import {
  Main,
  Container,
  Content,
  SectionOne,
  SectionOneSection,
} from "./styles";
function Homes() {
  const [selectedHome, setSelectedHome] = useState<String>('homeStatus')
  const navigate = useNavigate();

  const getSelected = (selected:String) => {
    setSelectedHome(selected);
  }
  return (
    <>
      <Header login={false} />
      <Main id="main">
        <Container>
          <SideNav handleSelect={getSelected} />
          <Content>
            <TopPath />
            <SectionOne>
              {selectedHome === 'homeStatus' ? <HomeStatus /> : null}
              {selectedHome === 'configuration' ? <Configuration /> : null}
              {selectedHome === 'softwareUpdate' ? <SoftwareUpdate /> : null}
              {selectedHome === 'subscriptions' ? <Subscriptions /> : null}
              {selectedHome === 'licensing' ? <Licensing /> : null}
              {selectedHome === 'configuredDevices' ? <ConfiguredDevices /> : null}
              {selectedHome === 'smartNetwork' ? <SmartNetwork /> : null}
              {selectedHome === 'hostProcess' ? <HostProcess /> : null}
              {selectedHome === 'systemStates' ? <SystemStates /> : null}
              {selectedHome === 'services' ? <Services /> : null}
              {selectedHome === 'logs' ? <Logs /> : null}
              {selectedHome === 'jobsInfo' ? <JobsInfo /> : null}
              <SectionOneSection>
              </SectionOneSection>
            </SectionOne>
          </Content>
        </Container>
      </Main>
    </>
  );
}

export default Homes;