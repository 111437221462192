import styled from 'styled-components';

export const Main = styled.div`
  background-color: rgb(248, 248, 248);
  padding-top: 64px;
  min-height: calc(100vh - 156px);
  max-width: 1440px;
  margin: 0px auto;
  display: block;
  box-sizing: inherit;
`;

export const Container = styled.div`
  @media screen and (min-width: 1280px) {
    padding: 0px 40px;
  }

  @media screen and (min-width: 768px) {
    // padding: 0px 24px;
  }

  // padding: 0px 12px;
  box-sizing: inherit;
`;

export const Content = styled.div`
  @media screen and (min-width: 1280px) {
    margin-top: 0px;
    margin-left: 280px;
  }

  @media screen and (min-width: 768px) {
    // margin-top: 0px;
    // margin-left: 96px;
  }

  // margin-top: 80px;
  // margin-left: 0px;
  display: block;
  box-sizing: inherit;
`;

export const SectionOne = styled.section`
  display: flex;
  flex-direction: column;
  box-sizing: inherit;
`;

export const SectionOneSection = styled.section`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  box-sizing: inherit;
`;