import styled from 'styled-components';

import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

export const CustomContainer = styled(Container)`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 0px 20px;
  box-sizing: inherit;
`;

export const CustomNavbar = styled(Navbar)`
  height: 60px;
  position: fixed;
  z-index: 98;
  width: 100vw;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  transition: background-color 0.3s ease 0s;
`;

export const CustomNavbarBrand = styled.a`
  color: rgb(219, 217, 214);
  display: flex;
  flex-flow: column;
  gap: 6px;
  text-decoration: none;
  background-color: transparent;
  box-sizing: inherit;

  &:hover {
    color: rgb(219, 217, 214);
    text-decoration: none;
  }

`;

export const LogoDiv = styled.div`
  max-width: 138px;
  box-sizing: inherit;
  display: block;

  &:hover {
    cursor: pointer;
  }
`;

export const LogoDescription = styled.div`
  font-size: 8px;
  letter-spacing: 2px;
  text-transform: uppercase;
  box-sizing: inherit;
`;

export const CustomNav = styled(Nav)`
  margin: 0px;
  padding: 0px;
  display: flex;
  box-sizing: inherit;
  `;

export const ItemA = styled.a`
  color: #A89968;
  font-size: 19px;
  line-height: 14px;
  text-decoration: none;
  position: relative;
  padding: 25px 16px;
  background-color: transparent;
  box-sizing: inherit;
  font-family: "Gotham", "Roboto", sans-serif;

  &:hover {
    color: #A89968;
    text-decoration: none;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const ItemDiv = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  box-sizing: inherit;
`;

export const ItemSelected = styled.div`
  content: "";
  display: block;
  background-color: #A89968;
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: 0px;
  left: 0px;
`;

export const UserDiv = styled.div`
  display: block;
  position: relative;
  margin-left: 16px;
  box-sizing: inherit;
`;

export const UserButton = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px;
  padding: 0px 12px;
  height: 44px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: transparent;
  backdrop-filter: none;
  text-transform: none;
  overflow: visible;
  box-sizing: inherit;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;

  &:hover {
    background-color: #A89968;
  }
`;

export const ButtonDivOne = styled.div`
  flex: 0 0 auto;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  overflow: hidden;
  box-sizing: inherit;
`;

export const ButtonDivTwo = styled.div`
  color: rgb(242, 242, 242);
  font-weight: 600;
  margin: 0px 16px;
  box-sizing: inherit;
`;