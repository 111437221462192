import styled from 'styled-components';

export const InnerHeader = styled.div`
  flex-wrap: nowrap;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  box-sizing: inherit;
`;

export const InnerHeaderDivOne = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 16px 0px;
  box-sizing: inherit;
`;

export const InnerHeaderDivOneH4 = styled.h4`
  font-size: 28px;
  line-height: 40px;
  font-weight: bold;
  margin: 0px;
  box-sizing: inherit;
`;

export const InnerHeaderDivTwo = styled.div`
  @media screen and (min-width: 1024px) {
    width: 384px;
    order: 0;
  }
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 10px auto;
  box-sizing: inherit;
`;

export const InnerHeaderDivThree = styled.div`
  display: flex;
  box-sizing: inherit;
`;

export const InnerHeaderDivThreeButton = styled.button`
  height: 40px;
  padding: 8px 16px;
  background-color: transparent;
  appearance: none;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: bold;
  width: fit-content;
  font-size: 16px;
  line-height: 18px;
  font-family: "Gotham", "Roboto", sans-serif;
  color: #A89968;
  border: 1px solid #A89968;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease 0s;
  user-select: none;
  white-space: nowrap;
  text-transform: none;
  overflow: visible;
  margin: 0px;
  box-sizing: inherit;
`;

export const InnerHeaderDivThreeButtonSpanOne = styled.span`
  padding: 0px 16px;
  font-size: 16px;
  line-height: 18px;
  color: #A89968;
  box-sizing: inherit;
`;

export const InnerHeaderDivThreeButtonSpanTwo = styled.span`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: inherit;
`;