import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  DivTwo,
  DivTwoDiv,
  DivTwoDivRow,
  DivTwoDivRowDivFive,
  DivTwoDivRowDivFour,
  DivTwoDivRowDivOne,
  DivTwoDivRowDivOneDiv,
  DivTwoDivRowDivSeven,
  DivTwoDivRowDivSix,
  DivTwoDivRowDivThree,
  DivTwoDivRowDivTwo,
  DivTwoDivRowGroup,
  DivTwoDivRowGroupA,
  DivTwoDivRowGroupACell,
  DivTwoDivRowGroupACellLicense,
  DivTwoDivRowGroupACellLicenseImg,
  DivTwoDivRowGroupACellOne,
  DivTwoDivRowGroupACellSubscriptionDivOne,
  DivTwoDivRowGroupACellTwo,
  DivTwoDivRowGroupACellTwoDiv,
  DivTwoDivRowGroupACellTwoDivImg,
  DivTwoDivRowGroupACellTwoDivSubText,
  DivTwoDivRowGroupACellTwoDivText,
  DivTwoSS,
  DivTwoDivSS,
  DivTwoDivRowSS,
  DivTwoDivRowDivFourSS,
  DivTwoDivRowDivOneDivSS,
  DivTwoDivRowDivThreeSS,
  DivTwoDivRowDivTwoSS,
  DivTwoDivRowGroupSS,
  DivTwoDivRowGroupASS,
  DivTwoDivRowGroupACellSS,
  DivTwoDivRowGroupACellTwoSS,
  DivTwoDivRowGroupACellTwoDivTextSS,
  DivTwoDivRowGroupACellTwoDivTextConfig,
  DivTwoDivRowGroupACellConfigSvgDiv,
  DivTwoDivRowGroupACellTwoConfig,
  DivTwoDivRowGroupACellTwoDivTextSoftwareUpdate,
  DivTwoDivRowGroupACellTwoDivTextAppliedLicenses,
  DivTwoDivRowGroupAppliedLicenses,
  DivTwoDivRowGroupAAppliedLicenses,
  NameTwoAppliedLicenses,
  DivTwoDivRowGroupACellTwoAppliedLicenses,
  DivTwoDivRowGroupACellLicenseCode,
  DivTwoDivRowGroupACellOrderNumber,
  DivTwoDivRowGroupACellPurchaseOrder,
  DivTwoDivRowGroupACellActivated,
  DivTwoDivRowGroupACellEnds,
  DivTwoDivRowGroupACellNotes,
  DivTwoDivRowDivThreeAppliedLicenses,
  DivTwoDivRowGroupACellMarkerSvg,

} from './styles';
import { useNavigate } from 'react-router-dom';

import M16Icon from "../../assets/M16Icon.png";
import subscriptionImg from "../../assets/essential-plan.svg";
import { ControllerContext } from '../../contexts/controller';

interface ComponentProps {
  listType: string;
  pollTime?: string;
  files?: string;
  confirmed?: string;
  data?: string;
  readingFile?: string;
}

export default function Lists(ComponentProps: ComponentProps) {
  const [serials, setSerials] = useState<any>([]);
  const [configurations, setConfigurations] = useState<any>([{
    name: 'Roberto_20230303_V01.rpmConfig',
    updated: '4/5/2023, 3:24 AM',
  }]);
  const [updates, setUpdates] = useState<any>([{
    version: '10.2.2-cprd1-pro:503',
    released: '4/6/2023, 10:21 AM',
  }]);
  const [appliedLicenses, setAppliedLicenses] = useState<any>([{
    name: 'RUNTIME LICENSE - SMART 9',
    nameTwo: 'OSL-SMRT9-00',
    licenseCode: '318700067178891384',
    orderNumber: '00349637',
    purchaseOrder: '',
    activated: '8/30/2022, 8:37 AM',
    ends: '',
    notes: '10/06/2020 - Antares - F - 29/08/22 - estoque',
  },
  {
    name: 'Roehn Essentials 1 Year Subscription (Smart)',
    nameTwo: 'SSL-SMRTE-1YR',
    licenseCode: '318950271392379044',
    orderNumber: '00470990',
    purchaseOrder: 'PO-01904',
    activated: '3/3/2023, 10:17 AM',
    ends: '3/3/2024, 10:17 AM',
    notes: 'Auto Generated',
  }]);
  const [availableLicenses, setAvailableLicenses] = useState<any>([{
    license: 'RUNTIME LICENSE - SMART 9',
    orderNumber: '00349637',
    purchaseOrder: '',
    created: '8/30/2022, 8:37 AM',
    notes: '10/06/2020 - Antares - F - 29/08/22 - estoque',
  },
  {
    license: '318950271392379044',
    orderNumber: '00470990',
    purchaseOrder: 'PO-01904',
    created: '3/3/2023, 10:17 AM',
    notes: '',
  }]);
  const navigate = useNavigate();
  const { setSerialNumber, systemStates } = useContext(ControllerContext);

  const handleNavigate = () => {
    navigate('/Homes');
  }

  const handleNavigateDinamic = (serial: any) => {
    setSerialNumber(serial)
    navigate('/Homes');
  }
  const local = false;
  const serverIP = local ? 'http://localhost:3001/api' : 'https://onea.company/api';

  useEffect(() => {
    axios.get(serverIP + '/all/serials')
      .then((response) => {
        if (response.data) {
          setSerials(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {ComponentProps.listType === 'listing' && (
        <DivTwo id='role table'>
          <DivTwoDiv>
            <DivTwoDivRow id='role row'>
              <DivTwoDivRowDivOne id='colspan 1'>
                <DivTwoDivRowDivOneDiv>
                  <svg viewBox="0 0 24 24" role="presentation" style={{ height: "1.125rem", width: "1.125rem" }}>
                    <path d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"
                      fill="rgb(187, 168, 140)"
                    ></path>
                  </svg>
                </DivTwoDivRowDivOneDiv>
              </DivTwoDivRowDivOne>
              <DivTwoDivRowDivTwo id='colspan 2'>
                Home Name
                <DivTwoDivRowDivOneDiv />
              </DivTwoDivRowDivTwo>
              <DivTwoDivRowDivThree id='colspan 3'>
                Updated
                <DivTwoDivRowDivOneDiv />
              </DivTwoDivRowDivThree>
              <DivTwoDivRowDivFour id='colspan 4'>
                Version
                <DivTwoDivRowDivOneDiv />
              </DivTwoDivRowDivFour>
              <DivTwoDivRowDivFive id='colspan 5'>
                Subscriptions
                <DivTwoDivRowDivOneDiv />
              </DivTwoDivRowDivFive>
              <DivTwoDivRowDivSix id='colspan 6'>
                Renewal Date
                <DivTwoDivRowDivOneDiv />
              </DivTwoDivRowDivSix>
              <DivTwoDivRowDivSeven id='colspan 7'>
                Last Backup
                <DivTwoDivRowDivOneDiv />
              </DivTwoDivRowDivSeven>
            </DivTwoDivRow>
          </DivTwoDiv>
          <DivTwoDivRowGroup id='role rowgroup'>
            <DivTwoDivRowGroupA id='role row' onClick={handleNavigate}>
              <DivTwoDivRowGroupACellOne>
                <div style={{ boxSizing: 'inherit' }}>
                  <svg viewBox="0 0 24 24" role="presentation" style={{ height: "1.125rem", width: "1.125rem" }}>
                    <path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"
                      fill="rgb(35, 197, 20)"
                    ></path>
                  </svg>
                </div>
              </DivTwoDivRowGroupACellOne>
              <DivTwoDivRowGroupACellTwo id='img'>
                <div style={{ boxSizing: 'inherit' }}>
                  <DivTwoDivRowGroupACellTwoDiv>
                    <DivTwoDivRowGroupACellTwoDivImg src={M16Icon} alt='image' />
                  </DivTwoDivRowGroupACellTwoDiv>
                </div>
                <div style={{ marginLeft: '16px', boxSizing: 'inherit' }}>
                  <DivTwoDivRowGroupACellTwoDivText>
                    AG Office
                  </DivTwoDivRowGroupACellTwoDivText>
                  <DivTwoDivRowGroupACellTwoDivSubText>
                    <b>AG São Paulo</b>
                  </DivTwoDivRowGroupACellTwoDivSubText>
                </div>
              </DivTwoDivRowGroupACellTwo>
              <DivTwoDivRowGroupACell>
                12/20/2022
              </DivTwoDivRowGroupACell>
              <DivTwoDivRowGroupACell>
                <DivTwoDivRowGroupACellLicense>
                  M16 - 50W ECH100(V2)
                </DivTwoDivRowGroupACellLicense>
              </DivTwoDivRowGroupACell>
              <DivTwoDivRowGroupACell>
                <DivTwoDivRowGroupACellSubscriptionDivOne>
                  <div style={{ marginRight: '3px', boxSizing: 'inherit' }}>
                    <DivTwoDivRowGroupACellLicenseImg src={subscriptionImg} alt='subscriptionImg' />
                  </div>
                  <span style={{ textAlign: 'left', boxSizing: 'inherit' }}>
                    Roehn Essentials
                  </span>
                </DivTwoDivRowGroupACellSubscriptionDivOne>
              </DivTwoDivRowGroupACell>
              <DivTwoDivRowGroupACell>
                <div style={{ boxSizing: 'inherit' }}>
                  04/11/2024
                </div>
              </DivTwoDivRowGroupACell>
              <DivTwoDivRowGroupACell>
                04/06/2023
              </DivTwoDivRowGroupACell>
            </DivTwoDivRowGroupA>
          </DivTwoDivRowGroup>
        </DivTwo>
      )}
      {ComponentProps.listType === 'listing' && serials.map((serial: any, index: any) => {
        return (
          <>
            <DivTwoDivRowGroup id='role rowgroup' key={serial}>
              <DivTwoDivRowGroupA id='role row' onClick={() => handleNavigateDinamic(serial)}>
                <DivTwoDivRowGroupACellOne>
                  <div style={{ boxSizing: 'inherit' }}>
                    <svg viewBox="0 0 24 24" role="presentation" style={{ height: "1.125rem", width: "1.125rem" }}>
                      <path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"
                        fill="rgb(35, 197, 20)"
                      ></path>
                    </svg>
                  </div>
                </DivTwoDivRowGroupACellOne>
                <DivTwoDivRowGroupACellTwo id='img'>
                  <div style={{ boxSizing: 'inherit' }}>
                    <DivTwoDivRowGroupACellTwoDiv>
                      <DivTwoDivRowGroupACellTwoDivImg src={M16Icon} alt='image' />
                    </DivTwoDivRowGroupACellTwoDiv>
                  </div>
                  <div style={{ marginLeft: '16px', boxSizing: 'inherit' }}>
                    <DivTwoDivRowGroupACellTwoDivText>
                      {serial}
                    </DivTwoDivRowGroupACellTwoDivText>
                    <DivTwoDivRowGroupACellTwoDivSubText>
                      <b>AG São Paulo</b>
                    </DivTwoDivRowGroupACellTwoDivSubText>
                  </div>
                </DivTwoDivRowGroupACellTwo>
                <DivTwoDivRowGroupACell>
                  12/20/2022
                </DivTwoDivRowGroupACell>
                <DivTwoDivRowGroupACell>
                  <DivTwoDivRowGroupACellLicense>
                    M16 - 50W ECH100(V2)
                  </DivTwoDivRowGroupACellLicense>
                </DivTwoDivRowGroupACell>
                <DivTwoDivRowGroupACell>
                  <DivTwoDivRowGroupACellSubscriptionDivOne>
                    <div style={{ marginRight: '3px', boxSizing: 'inherit' }}>
                      <DivTwoDivRowGroupACellLicenseImg src={subscriptionImg} alt='subscriptionImg' />
                    </div>
                    <span style={{ textAlign: 'left', boxSizing: 'inherit' }}>
                      Roehn Essentials
                    </span>
                  </DivTwoDivRowGroupACellSubscriptionDivOne>
                </DivTwoDivRowGroupACell>
                <DivTwoDivRowGroupACell>
                  <div style={{ boxSizing: 'inherit' }}>
                    04/11/2024
                  </div>
                </DivTwoDivRowGroupACell>
                <DivTwoDivRowGroupACell>
                  04/06/2023
                </DivTwoDivRowGroupACell>
              </DivTwoDivRowGroupA>
            </DivTwoDivRowGroup>
          </>
        )
      })}
      {ComponentProps.listType === 'systemStates' && (
        <DivTwoSS id='role table'>
          <DivTwoDivSS>
            <DivTwoDivRowSS id='role row'>
              <DivTwoDivRowDivTwoSS id='colspan 2'>
                State Name
                <DivTwoDivRowDivOneDivSS />
                <svg viewBox="0 0 24 24" role="presentation" style={{ height: "1.125rem", width: "1.125rem" }}>
                  <path d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"
                    fill="rgb(187, 168, 140)"
                  ></path>
                </svg>
              </DivTwoDivRowDivTwoSS>
              <DivTwoDivRowDivThreeSS id='colspan 3'>
                StateType
                <DivTwoDivRowDivOneDivSS />
              </DivTwoDivRowDivThreeSS>
              <DivTwoDivRowDivFourSS id='colspan 4'>
                State Value
                <DivTwoDivRowDivOneDivSS />
              </DivTwoDivRowDivFourSS>
            </DivTwoDivRowSS>
          </DivTwoDivSS>
          {ComponentProps.listType === 'systemStates' && systemStates && Object.keys(systemStates).map((key: any, index: any) => {
            return (
              <DivTwoDivRowGroupSS id='role rowgroup' key={index}>
                <DivTwoDivRowGroupASS id='role row'>
                  <DivTwoDivRowGroupACellTwoSS id='img'>
                    <DivTwoDivRowGroupACellTwoDivTextSS>
                      {key}
                    </DivTwoDivRowGroupACellTwoDivTextSS>
                  </DivTwoDivRowGroupACellTwoSS>
                  <DivTwoDivRowGroupACellSS>
                    {typeof systemStates[key]}
                  </DivTwoDivRowGroupACellSS>
                  <DivTwoDivRowGroupACellSS>
                    <div style={{ boxSizing: 'inherit' }}>
                      {systemStates[key]}
                    </div>
                  </DivTwoDivRowGroupACellSS>
                </DivTwoDivRowGroupASS>
              </DivTwoDivRowGroupSS>
            )
          })}
        </DivTwoSS>
      )}
      {ComponentProps.listType === 'configuration' && (
        <DivTwoSS id='role table'>
          <DivTwoDivSS>
            <DivTwoDivRowSS id='role row'>
              <DivTwoDivRowDivTwoSS id='colspan 2'>
                Name
                <DivTwoDivRowDivOneDivSS />
                <svg viewBox="0 0 24 24" role="presentation" style={{ height: "1.125rem", width: "1.125rem" }}>
                  <path d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"
                    fill="rgb(187, 168, 140)"
                  ></path>
                </svg>
              </DivTwoDivRowDivTwoSS>
              <DivTwoDivRowDivThreeSS id='colspan 3'>
                Updated
                <DivTwoDivRowDivOneDivSS />
              </DivTwoDivRowDivThreeSS>
              <DivTwoDivRowDivFourSS id='colspan 4'>
                <DivTwoDivRowDivOneDivSS />
              </DivTwoDivRowDivFourSS>
            </DivTwoDivRowSS>
          </DivTwoDivSS>
          {ComponentProps.listType === 'configuration' && configurations && Object.keys(configurations).map((key: any, index: any) => {
            return (
              <DivTwoDivRowGroupSS id='role rowgroup' key={index}>
                <DivTwoDivRowGroupASS id='role row'>
                  <DivTwoDivRowGroupACellTwoConfig id='img'>
                    <svg
                      width="16"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0.00511048 9.82342C0.00171921 9.88185 0 9.94072 0 10V14H0.00250006L0.00194317 14.891C0.000651387 14.9272 0 14.9635 0 15V18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM2 12.1707C2.31278 12.0602 2.64936 12 3 12H9.26756C9.61337 11.4022 10.2597 11 11 11C12.1046 11 13 11.8954 13 13C13 14.1046 12.1046 15 11 15C10.2597 15 9.61337 14.5978 9.26756 14H3C2.44772 14 2 14.4477 2 15V18H14V7H9V2H2V7.17071C2.31278 7.06015 2.64936 7 3 7H4.26756C4.61337 6.4022 5.25972 6 6 6C7.10457 6 8 6.89543 8 8C8 9.10457 7.10457 10 6 10C5.25972 10 4.61337 9.5978 4.26756 9H3C2.44772 9 2 9.44772 2 10V12.1707Z"
                        fill="#D2CCC1">
                      </path></svg>
                    <DivTwoDivRowGroupACellTwoDivTextConfig>
                      {configurations[key].name}
                    </DivTwoDivRowGroupACellTwoDivTextConfig>
                  </DivTwoDivRowGroupACellTwoConfig>
                  <DivTwoDivRowGroupACellSS>
                    {configurations[key].updated}
                  </DivTwoDivRowGroupACellSS>
                  <DivTwoDivRowGroupACellConfigSvgDiv>
                    <svg
                      viewBox="0 0 24 24"
                      role="presentation"
                      style={{ width: '1.5rem', height: '1.5rem', right: 0 }}>
                      <path
                        d="M8,13H10.55V10H13.45V13H16L12,17L8,13M19.35,10.04C21.95,10.22 24,12.36 24,15A5,5 0 0,1 19,20H6A6,6 0 0,1 0,14C0,10.91 2.34,8.36 5.35,8.04C6.6,5.64 9.11,4 12,4C15.64,4 18.67,6.59 19.35,10.04M19,18A3,3 0 0,0 22,15C22,13.45 20.78,12.14 19.22,12.04L17.69,11.93L17.39,10.43C16.88,7.86 14.62,6 12,6C9.94,6 8.08,7.14 7.13,8.97L6.63,9.92L5.56,10.03C3.53,10.24 2,11.95 2,14A4,4 0 0,0 6,18H19Z"
                        style={{ fill: 'currentcolor' }}>
                      </path>
                    </svg>
                  </DivTwoDivRowGroupACellConfigSvgDiv>
                </DivTwoDivRowGroupASS>
              </DivTwoDivRowGroupSS>
            )
          })}
        </DivTwoSS>
      )}
      {ComponentProps.listType === 'softwareUpdate' && (
        <DivTwoSS id='role table'>
          <DivTwoDivSS>
            <DivTwoDivRowSS id='role row'>
              <DivTwoDivRowDivTwoSS id='colspan 2'>
                Name
                <DivTwoDivRowDivOneDivSS />
                <svg viewBox="0 0 24 24" role="presentation" style={{ height: "1.125rem", width: "1.125rem" }}>
                  <path d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"
                    fill="rgb(187, 168, 140)"
                  ></path>
                </svg>
              </DivTwoDivRowDivTwoSS>
              <DivTwoDivRowDivThreeSS id='colspan 3'>
                Updated
                <DivTwoDivRowDivOneDivSS />
              </DivTwoDivRowDivThreeSS>
              <DivTwoDivRowDivFourSS id='colspan 4'>
                <DivTwoDivRowDivOneDivSS />
              </DivTwoDivRowDivFourSS>
            </DivTwoDivRowSS>
          </DivTwoDivSS>
          {ComponentProps.listType === 'softwareUpdate' && updates && Object.keys(updates).map((key: any, index: any) => {
            return (
              <DivTwoDivRowGroupSS id='role rowgroup' key={index}>
                <DivTwoDivRowGroupASS id='role row'>
                  <DivTwoDivRowGroupACellTwoConfig id='img'>
                    <svg viewBox="0 0 24 24" role="presentation" style={{ width: '1.5rem', height: '1.5rem' }}>
                      <path
                        d="M14.6,16.6L19.2,12L14.6,7.4L16,6L22,12L16,18L14.6,16.6M9.4,16.6L4.8,12L9.4,7.4L8,6L2,12L8,18L9.4,16.6Z"
                        style={{ fill: 'rgb(210, 204, 193)' }}>
                      </path>
                    </svg>
                    <DivTwoDivRowGroupACellTwoDivTextSoftwareUpdate>
                      {updates[key].version}
                    </DivTwoDivRowGroupACellTwoDivTextSoftwareUpdate>
                  </DivTwoDivRowGroupACellTwoConfig>
                  <DivTwoDivRowGroupACellSS>
                    {updates[key].released}
                  </DivTwoDivRowGroupACellSS>
                  <DivTwoDivRowGroupACellConfigSvgDiv>
                    <svg
                      viewBox="0 0 24 24"
                      role="presentation"
                      style={{ width: '1.5rem', height: '1.5rem', right: 0 }}>
                      <path
                        d="M8,13H10.55V10H13.45V13H16L12,17L8,13M19.35,10.04C21.95,10.22 24,12.36 24,15A5,5 0 0,1 19,20H6A6,6 0 0,1 0,14C0,10.91 2.34,8.36 5.35,8.04C6.6,5.64 9.11,4 12,4C15.64,4 18.67,6.59 19.35,10.04M19,18A3,3 0 0,0 22,15C22,13.45 20.78,12.14 19.22,12.04L17.69,11.93L17.39,10.43C16.88,7.86 14.62,6 12,6C9.94,6 8.08,7.14 7.13,8.97L6.63,9.92L5.56,10.03C3.53,10.24 2,11.95 2,14A4,4 0 0,0 6,18H19Z"
                        style={{ fill: 'currentcolor' }}>
                      </path>
                    </svg>
                  </DivTwoDivRowGroupACellConfigSvgDiv>
                </DivTwoDivRowGroupASS>
              </DivTwoDivRowGroupSS>
            )
          })}
        </DivTwoSS>
      )}
      {ComponentProps.listType === 'appliedLicenses' && (
        <DivTwoSS id='role table'>
          <DivTwoDivSS>
            <DivTwoDivRowSS id='role row'>
              <DivTwoDivRowDivTwoSS id='colspan 2' style={{ fontSize: '12px' }}>
                Name
                <DivTwoDivRowDivOneDivSS />
                <svg viewBox="0 0 24 24" role="presentation" style={{ height: "1.125rem", width: "1.125rem" }}>
                  <path d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"
                    fill="rgb(187, 168, 140)"
                  ></path>
                </svg>
              </DivTwoDivRowDivTwoSS>
              <DivTwoDivRowDivThreeAppliedLicenses id='colspan 3'>
                License Code
                <DivTwoDivRowDivOneDivSS />
              </DivTwoDivRowDivThreeAppliedLicenses>
              <DivTwoDivRowDivThreeAppliedLicenses id='colspan 3'>
                Order Number
                <DivTwoDivRowDivOneDivSS />
              </DivTwoDivRowDivThreeAppliedLicenses>
              <DivTwoDivRowDivThreeAppliedLicenses id='colspan 3'>
                Purchase Order
                <DivTwoDivRowDivOneDivSS />
              </DivTwoDivRowDivThreeAppliedLicenses>
              <DivTwoDivRowDivThreeAppliedLicenses id='colspan 3'>
                Activated
                <DivTwoDivRowDivOneDivSS />
              </DivTwoDivRowDivThreeAppliedLicenses>
              <DivTwoDivRowDivThreeAppliedLicenses id='colspan 3'>
                Ends
                <DivTwoDivRowDivOneDivSS />
              </DivTwoDivRowDivThreeAppliedLicenses>
              <DivTwoDivRowDivThreeAppliedLicenses id='colspan 3'>
                Notes
                <DivTwoDivRowDivOneDivSS />
              </DivTwoDivRowDivThreeAppliedLicenses>
            </DivTwoDivRowSS>
          </DivTwoDivSS>
          {ComponentProps.listType === 'appliedLicenses' && appliedLicenses && Object.keys(appliedLicenses).map((key: any, index: any) => {
            return (
              <DivTwoDivRowGroupAppliedLicenses id='role rowgroup' key={index}>
                <DivTwoDivRowGroupAAppliedLicenses id='role row'>
                  <DivTwoDivRowGroupACellTwoAppliedLicenses id='img'>
                    <DivTwoDivRowGroupACellTwoDivTextAppliedLicenses>
                      {appliedLicenses[key].name}
                      <NameTwoAppliedLicenses>
                        {appliedLicenses[key].nameTwo}
                      </NameTwoAppliedLicenses>
                    </DivTwoDivRowGroupACellTwoDivTextAppliedLicenses>
                  </DivTwoDivRowGroupACellTwoAppliedLicenses>
                  <DivTwoDivRowGroupACellLicenseCode>
                    {appliedLicenses[key].licenseCode}
                  </DivTwoDivRowGroupACellLicenseCode>
                  <DivTwoDivRowGroupACellOrderNumber>
                    {appliedLicenses[key].orderNumber}
                  </DivTwoDivRowGroupACellOrderNumber>
                  <DivTwoDivRowGroupACellPurchaseOrder>
                    {appliedLicenses[key].purchaseOrder}
                  </DivTwoDivRowGroupACellPurchaseOrder>
                  <DivTwoDivRowGroupACellActivated>
                    {appliedLicenses[key].activated}
                  </DivTwoDivRowGroupACellActivated>
                  <DivTwoDivRowGroupACellEnds>
                    {appliedLicenses[key].ends}
                  </DivTwoDivRowGroupACellEnds>
                  <DivTwoDivRowGroupACellNotes>
                    {appliedLicenses[key].notes}
                  </DivTwoDivRowGroupACellNotes>
                  <DivTwoDivRowGroupACellMarkerSvg>
                    <svg
                      viewBox="0 0 24 24"
                      role="presentation"
                      style={{ height: "24px", width: "24px" }}
                    >
                      <path
                        d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
                        style={{ fill: 'currentcolor' }}>
                      </path>
                    </svg>
                  </DivTwoDivRowGroupACellMarkerSvg>
                </DivTwoDivRowGroupAAppliedLicenses>
              </DivTwoDivRowGroupAppliedLicenses>
            )
          })}
        </DivTwoSS>
      )}
      {ComponentProps.listType === 'availableLicenses' && (
        <DivTwoSS id='role table'>
          <DivTwoDivSS>
            <DivTwoDivRowSS id='role row'>
              <DivTwoDivRowDivTwoSS id='colspan 2'>
                License
                <DivTwoDivRowDivOneDivSS />
                <svg viewBox="0 0 24 24" role="presentation" style={{ height: "1.125rem", width: "1.125rem" }}>
                  <path d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"
                    fill="rgb(187, 168, 140)"
                  ></path>
                </svg>
              </DivTwoDivRowDivTwoSS>
              <DivTwoDivRowDivThreeSS id='colspan 3'>
                Order Number
                <DivTwoDivRowDivOneDivSS />
              </DivTwoDivRowDivThreeSS>
              <DivTwoDivRowDivThreeSS id='colspan 3'>
                Purchase Order
                <DivTwoDivRowDivOneDivSS />
              </DivTwoDivRowDivThreeSS>
              <DivTwoDivRowDivThreeSS id='colspan 3'>
                Created
                <DivTwoDivRowDivOneDivSS />
              </DivTwoDivRowDivThreeSS>
              <DivTwoDivRowDivThreeSS id='colspan 3'>
                Notes
              </DivTwoDivRowDivThreeSS>
            </DivTwoDivRowSS>
          </DivTwoDivSS>
          {ComponentProps.listType === 'availableLicenses' && availableLicenses && Object.keys(availableLicenses).map((key: any, index: any) => {
            return (
              <DivTwoDivRowGroupSS id='role rowgroup' key={index}>
                <DivTwoDivRowGroupASS id='role row'>
                  <DivTwoDivRowGroupACellTwoConfig id='img'>
                    <svg viewBox="0 0 24 24" role="presentation" style={{ width: '1.5rem', height: '1.5rem' }}>
                      <path
                        d="M14.6,16.6L19.2,12L14.6,7.4L16,6L22,12L16,18L14.6,16.6M9.4,16.6L4.8,12L9.4,7.4L8,6L2,12L8,18L9.4,16.6Z"
                        style={{ fill: 'rgb(210, 204, 193)' }}>
                      </path>
                    </svg>
                    <DivTwoDivRowGroupACellTwoDivTextSoftwareUpdate>
                      {availableLicenses[key].license}
                    </DivTwoDivRowGroupACellTwoDivTextSoftwareUpdate>
                  </DivTwoDivRowGroupACellTwoConfig>
                  <DivTwoDivRowGroupACellOrderNumber>
                    {appliedLicenses[key].orderNumber}
                  </DivTwoDivRowGroupACellOrderNumber>
                  <DivTwoDivRowGroupACellPurchaseOrder>
                    {appliedLicenses[key].purchaseOrder}
                  </DivTwoDivRowGroupACellPurchaseOrder>
                  <DivTwoDivRowGroupACellActivated>
                    {appliedLicenses[key].activated}
                  </DivTwoDivRowGroupACellActivated>
                  <DivTwoDivRowGroupACellEnds>
                    {appliedLicenses[key].ends}
                  </DivTwoDivRowGroupACellEnds>
                  <DivTwoDivRowGroupACellNotes>
                    {appliedLicenses[key].notes}
                  </DivTwoDivRowGroupACellNotes>
                  <DivTwoDivRowGroupACellMarkerSvg>
                  <svg
                      viewBox="0 0 24 24"
                      role="presentation"
                      style={{ height: "24px", width: "24px" }}
                    >
                      <path
                        d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
                        style={{ fill: 'currentcolor' }}>
                      </path>
                    </svg>
                  </DivTwoDivRowGroupACellMarkerSvg>
                </DivTwoDivRowGroupASS>
              </DivTwoDivRowGroupSS>
            )
          })}
        </DivTwoSS>
      )}
    </>
  );
}