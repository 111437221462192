import styled from 'styled-components'

export const RootDiv = styled.div`
  box-sizing: inherit;
  display: block;
`;

export const OuterContainer = styled.div`
  background-color: rgb(248, 248, 248);
  padding-top: 64px;
  min-height: calc(100vh - 156px);
  max-width: 1440px;
  margin: 0px auto;
  display: block;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased !important;
`;

export const MainContainer = styled.div`
  padding: 16px 40px;
`;

export const TitleDiv = styled.div`
  margin-bottom: 16px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
`;

export const MainTitle = styled.h1`
  margin: 0px;
  color: rgb(56, 46, 43);
  font-size: 24px;
  font-family: Gotham, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
`;

export const SearchDiv = styled.div`
  margin-bottom: 16px;
  box-shadow: rgba(0, 0, 0, 0.02) 10px 10px 10px;
`;
