import axios from 'axios';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface ControllerContextData {
  serialNumber: string;
  systemStates: any;
  setSerialNumber: (serial: string) => void;
  getInformation: (type: string) => any;
  clearController: () => void;
}

// type SystemStates = {
//   pollTime?: number;
//   files?: string[];
//   confirmed?: string[];
//   data?: string[];
//   readingFile?: string[];
// }

const ControllerContext = createContext<ControllerContextData>({} as ControllerContextData);

const ControllerProvider = ({ children }: { children: ReactNode }) => {
  const [serialNumber, setSerialNumberValue] = useState<string>("");
  const [pollTime, setPollTime] = useState<string>('');
  const [files, setFiles] = useState<string>('');
  const [confirmed, setConfirmed] = useState<string>('');
  const [data, setData] = useState<string>('');
  const [readingFile, setReadingFile] = useState<string>('');
  const [systemStates, setSystemStates] = useState({pollTime, files, confirmed, data, readingFile});

  const local = false;
  const serverIP = local ? 'http://localhost:3001/api' : 'https://onea.company/api';
  const getRoutes = ['/polltime', '/files', '/confirmed', '/data', '/readingfile']

  //routes to get 
 //get :serial/polltime
  //get :serial/files
  //get :serial/confirmed
  //get :serial/data
  //get :serial/readingfile

  useEffect(() => {
    if(serialNumber){
      const requests = getRoutes.map((url) => axios.get(serverIP + '/' + serialNumber + url));
      axios.all(requests).then((responses) => {
        let localSystemStates = systemStates;
        responses.forEach((resp, index) => {
          switch (index) {
            case 0:
              setPollTime(resp.data);
              localSystemStates.pollTime = resp.data;
              break;
            case 1:
              setFiles(resp.data);
              localSystemStates.files = resp.data;
              break;
            case 2:
              setConfirmed(resp.data);
              localSystemStates.confirmed = resp.data;
              break;
            case 3:
              setData(resp.data);
              localSystemStates.data = resp.data;
              break;
            case 4:
              setReadingFile(resp.data);
              localSystemStates.readingFile = resp.data;
              break;
            default:
              break;
          }
        });
        console.log("localSystemStates", localSystemStates)
        setSystemStates(localSystemStates);
      });
    }
  }, [serialNumber]);

  const setSerialNumber = (serial: string) => {
    setSerialNumberValue(serial);
  };

  const getInformation = (type:string) => {
    switch (type) {
      case 'pollTime':
        return pollTime;
      case 'files':
        return files;
      case 'confirmed':
        return confirmed;
      case 'data':
        return data;
      case 'readingFile':
        return readingFile;
      default:
        return '';
    }
  };

  const clearController = () => {
    setSerialNumberValue('');
    setPollTime('');
    setFiles('');
    setConfirmed('');
    setData('');
    setReadingFile('');
  }
  
  return (
    <ControllerContext.Provider
      value={{
        serialNumber,
        setSerialNumber,
        getInformation,
        clearController,
        systemStates
      }}>
      {children}
    </ControllerContext.Provider>
  );
}

export { ControllerProvider, ControllerContext };


