import styled from 'styled-components';

export const DivTwoDiv = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  border-radius: 8px;
  overflow: hidden;
  box-sizing: inherit;
`;

export const DivTwo = styled.div`
  @media screen and (min-width: 1024px) {
    width: 384px;
    order: 0;
  }
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 10px auto;
  box-sizing: inherit;
`;

export const DivTwoDivLabel = styled.label`
  display: flex;
  flex-direction: row;
  width: 100%;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease 0s;
  user-select: none;
  box-sizing: inherit;
`;

export const DivTwoDivInput = styled.input`
  box-sizing: border-box;
  padding: 0px;
  display: none;
  overflow: visible;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0px;
`;

export const DivTwoDivInputSpan = styled.span`
  color: #A89968;
  border: 1px solid #A89968;
  background: rgb(253, 245, 240);
  border-radius: 8px 0px 0px 8px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 900;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding: 8px 0px;
  width: 100%;
  box-sizing: inherit;
`;

export const MiddleButtonDivTwoDivInputSpan = styled.span`
  color: #A89968;
  border: 1px solid #A89968;
  background: rgb(253, 245, 240);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 900;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding: 8px 0px;
  width: 100%;
  box-sizing: inherit;
`;

export const DivTwoDivInputSpanTwo = styled.span`
  border-radius: 0px 8px 8px 0px;
  display: flex;
  color: #A89968;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 900;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding: 8px 0px;
  width: 100%;
  border: 1px solid #A89968;
  box-sizing: inherit;
`;

export const OneButtonDivTwoDivInputSpan = styled.span`
  color: #A89968;
  border: 1px solid #A89968;
  background: rgb(253, 245, 240);
  border-radius: 8px;
  flex-wrap: wrap;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 900;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding: 8px 16px;
  width: 100%;
  box-sizing: inherit;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

export const OneButtonDivTwoDiv = styled.div`
  font-size: 16px;
  display: block;
  border-radius: 8px;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

export const OneButtonDivTwoDivLabel = styled.label`
  width: 100%;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease 0s;
`;

export const TwoButtonsDivTwoDiv = styled.div`
  font-size: 16px;
  display: block;
  border-radius: 8px;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

export const TwoButtonsDivTwoDivLabel = styled.label`
  width: 100%;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease 0s;
`;

export const TwoButtonsDivTwoDivInputSpanLeftButton = styled.span`
  color: #A89968;
  border: 1px solid #A89968;
  background: rgb(253, 245, 240);
  border-radius: 8px 0px 0px 8px;
  flex-wrap: wrap;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 900;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding: 8px 0px;
  width: 270px;
  box-sizing: inherit;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: center;
  white-space: nowrap;
`;

export const TwoButtonsDivTwoDivInputSpanRightButton = styled.span`
  color: #A89968;
  border: 1px solid #A89968;
  background: rgb(253, 245, 240);
  border-radius: 0px 8px 8px 0px;
  flex-wrap: wrap;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 900;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding: 8px 0px;
  width: 270px;
  box-sizing: inherit;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: center;
  white-space: nowrap;
`;