import { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import bg_image from '../../assets/bg_image.jpg';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import Header from '../../components/Header';

import { BackgroundImage, AText, BottomTexts, ButtonContainer, FormContainer, LoginContainer, LoginSubTitle, LoginTitle, MainContainer, Hyperlink, PassContainer, StyledFormControl, StyledFormGroup, StyledInputGroup, CustomButton } from './styles';

import { AiOutlineEyeInvisible } from 'react-icons/ai';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordInputType, setPasswordInputType] = useState('password');

  const navigate = useNavigate();

  const handleLogin = () => {
    if (email === 'adams@audiogene.com.br' && password === 'qazwsx') {
      navigate('/Listing');
    } else {
      alert('Wrong email or password');
    }
  }

  const handleShowPassword = () => {
    if (passwordInputType === 'password') {
      setPasswordInputType('text');
    } else {
      setPasswordInputType('password');
    }
  }

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <>
    <div
        style={{
          position: 'absolute',
          zIndex: 0,
          top: 0,
          left: 0,
          minWidth: '100%',
          height: '100%'
        }}
      >
      <BackgroundImage src={bg_image} />
    </div>
        <div
          style={{
            display: 'flex',
            position: 'relative',
            boxSizing: 'inherit',
          }}
        >
      <Header login={true}/>
      <MainContainer>
        <LoginContainer>
          <LoginTitle>Sign In</LoginTitle>
          <LoginSubTitle>Please use your Roehn Account credentials to sign in.</LoginSubTitle>
          <FormContainer >
            <StyledFormGroup controlId="formBasicEmail">
              <StyledFormControl type="email" placeholder="Email" onChange={handleEmailChange} />
            </StyledFormGroup>
            <p style={{margin:'-12px 0px 12px', fontSize:'12px', lineHeight:'16px', minHeight:'16px'}}></p>
            <StyledInputGroup>
              <StyledFormControl type={passwordInputType} placeholder="Password" onChange={handlePasswordChange} />
              <InputGroup.Text onClick={handleShowPassword}> <AiOutlineEyeInvisible style={{cursor: 'pointer'}}/> </InputGroup.Text>
            </StyledInputGroup>
            <p style={{margin:'-12px 0px 12px', fontSize:'12px', lineHeight:'16px', minHeight:'16px'}}></p>
            <PassContainer>
              <Hyperlink>Forgot your Password?</Hyperlink>
            </PassContainer>
            <ButtonContainer>
              <CustomButton type="button" onClick={handleLogin} style={{alignSelf: 'center'}}>
                Sign In
              </CustomButton>
            </ButtonContainer>
            <BottomTexts>
               By signing in, I agree to the<AText > Terms of Service</AText> and<AText> Privacy Policy</AText>. 
            </BottomTexts>
          </FormContainer>
        </LoginContainer>
      </MainContainer>
    </div>
    </>
  )
}
export default LoginPage;