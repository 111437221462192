import styled from 'styled-components';

export const BodyDiv = styled.div`
  display: block;
  box-sizing: inherit;
`;

export const BodyDivDiv = styled.div`
  @media screen and (min-width: 1280px) {
    overflow: initial;
  }

  @media screen and (min-width: 768px) {
    border-radius: 8px;
  }

  position: relative;
  box-sizing: inherit;
`;


export const BodyDivDivOne = styled.div`
  @media screen and (min-width: 768px) {
    width: max-content;
    min-width: 100%;
  }

  box-sizing: inherit;
`;

export const BodyDivDivOneDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: inherit;
`;

export const BodyDivDivOneDivOne = styled.div`
  position: absolute;
  width: 100%;
  box-sizing: inherit;
`;

export const BodyDivDivOneDivTwo = styled.div`
  min-width: 0px;
  
  @media screen and (min-width: 768px) {
    display: flex;
  }

  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 0px 1px;
  border-radius: 8px 8px 0px 0px;
`;

export const BodyDivDivOneDivTwoDiv = styled.div`
  @media screen and (min-width: 768px) {
    display: block;
  }

  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
  display: none;
  box-sizing: inherit;
`;


export const BodyDivDivOneDivTwoDivRow = styled.div`
  display: flex;
  flex: 1 0 auto;
  min-width: 0px;
  height: 48px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px;
  box-sizing: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
`;

export const BodyDivDivOneDivTwoDivRowDivOne = styled.div`
  box-sizing: border-box;
  flex: 50 0 auto;
  min-width: 0px;
  width: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
`;


export const BodyDivDivOneDivTwoDivRowDivOneDiv = styled.div`
  position: relative;
  box-sizing: inherit;
`;


export const BodyDivDivOneDivTwoDivRowDivTwo = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 24px;
  width: 25% !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
`;

export const BodyDivDivOneDivTwoDivRowDivThree = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
`;

export const BodyDivDivOneDivTwoDivRowDivFour = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
  font-family: Gotham, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
`;

export const BodyDivDivOneDivTwoDivRowDivFive = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
`;

export const BodyDivDivOneDivTwoDivRowDivSix = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
`;

export const BodyDivDivOneDivTwoDivRowDivSeven = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  padding: 13px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgb(105, 96, 87);
`;

export const BodyDivDivOneDivTwoDivRowGroup = styled.div`
  width: 100%;
  font-weight: 400;
  color: rgb(105, 96, 87);
  box-sizing: inherit;
  &:hover {
    box-shadow: inset 0px 0px 0px 1px #A89968;
  }
`;

export const BodyDivDivOneDivTwoDivRowGroupA = styled.a`
  display: flex;
  flex: 1 0 auto;
  min-width: 0px;
  height: 64px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px;
  transition: outline 0.3s ease 0s;
  will-change: box-shadow;
  text-decoration: none !important;
  color: unset;
  background-color: transparent;
  box-sizing: inherit;
  cursor: pointer;
  font-weight: 400;
`;

export const BodyDivDivOneDivTwoDivRowGroupACellOne = styled.div`
  box-sizing: border-box;
  flex: 50 0 auto;
  min-width: 0px;
  width: 50px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 24px;
  font-size: 14px;
  line-height: 16px;
  color: unset;
`;

export const BodyDivDivOneDivTwoDivRowGroupACellTwo = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 24px;
  font-size: 14px;
  line-height: 16px;
  width: 25% !important;
`;

export const BodyDivDivOneDivTwoDivRowGroupACellTwoDiv = styled.div`
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  box-sizing: inherit;
  font-size: 14px;
  line-height: 16px;
`;

export const BodyDivDivOneDivTwoDivRowGroupACellTwoDivImg = styled.img`
  flex: 0 0 auto;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  filter: invert(1);
  opacity: 0.7;
  transform: translateZ(0px);
  border-style: none;
  box-sizing: inherit;
  overflow-clip-margin: content-box;
  overflow: clip;
`;

export const BodyDivDivOneDivTwoDivRowGroupACellTwoDivText = styled.div`
  margin-bottom: 5px;
  color: rgb(128, 126, 126);
  box-sizing: inherit;
`;

export const BodyDivDivOneDivTwoDivRowGroupACellTwoDivSubText = styled.div`
  color: rgb(56, 46, 43);
  box-sizing: inherit;
`;


export const BodyDivDivOneDivTwoDivRowGroupACell = styled.div`
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  height: 100%;
  padding: 24px;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none !important;
`;

export const BodyDivDivOneDivTwoDivRowGroupACellLicense = styled.div`
  padding: 0px;
  font-weight: 700;
  color: rgb(56, 46, 43);
  box-sizing: inherit;
`;

export const BodyDivDivOneDivTwoDivRowGroupACellLicenseImg = styled.img`
  color: rgb(56, 46, 43);
  width: 28px;
  height: 28px;
  margin-right: 8px;
  border-style: none;
  box-sizing: inherit;
  overflow-clip-margin: content-box;
  overflow: clip;
`;


export const BodyDivDivOneDivTwoDivRowGroupACellSubscriptionDivOne = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-weight: 700;
  color: rgb(56, 46, 43);
  box-sizing: inherit;
`;