
import { useContext, useState } from "react";
import {
  AsideContainer,
  AsideTopDiv,
  AsideTopDivButton,
  AsideTopDivSpan,
  AsideUL,
  AsideLI,
  AsideA,
  AsideASpan,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { ControllerContext } from "../../contexts/controller";

const HomeSVG = () => {
  return(
    <svg viewBox="0 0 24 24" role="presentation"  style={{height: "1.5rem", width: "1.5rem"}}>
      <path d="M12 5.69L17 10.19V18H15V12H9V18H7V10.19L12 5.69M12 3L2 12H5V20H11V14H13V20H19V12H22L12 3Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

const ConfigurationSVG = () => {
  return(
    <svg viewBox="0 0 24 24" role="presentation"  style={{height: "1.5rem", width: "1.5rem"}}>
      <path d="M3,7H9V13H3V7M3,3H21V5H3V3M21,11V13H11V11H21M3,15H17V17H3V15M3,19H21V21H3V19Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

const SoftwareUpdateSVG = () => {
  return(
    <svg viewBox="0 0 24 24" role="presentation"  style={{height: "1.5rem", width: "1.5rem"}}>
      <path d="M14.6,16.6L19.2,12L14.6,7.4L16,6L22,12L16,18L14.6,16.6M9.4,16.6L4.8,12L9.4,7.4L8,6L2,12L8,18L9.4,16.6Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

const SubscriptionsSVG = () => {
  return(
    <svg viewBox="0 0 24 24" role="presentation"  style={{height: "1.5rem", width: "1.5rem"}}>
      <path d="M23 12L20.6 9.2L20.9 5.5L17.3 4.7L15.4 1.5L12 3L8.6 1.5L6.7 4.7L3.1 5.5L3.4 9.2L1 12L3.4 14.8L3.1 18.5L6.7 19.3L8.6 22.5L12 21L15.4 22.5L17.3 19.3L20.9 18.5L20.6 14.8L23 12M18.7 16.9L16 17.5L14.6 19.9L12 18.8L9.4 19.9L8 17.5L5.3 16.9L5.5 14.1L3.7 12L5.5 9.9L5.3 7.1L8 6.5L9.4 4.1L12 5.2L14.6 4.1L16 6.5L18.7 7.1L18.5 9.9L20.3 12L18.5 14.1L18.7 16.9M16.6 7.6L18 9L10 17L6 13L7.4 11.6L10 14.2L16.6 7.6Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

const LicensingSVG = () => {
  return(
    <svg viewBox="0 0 24 24" role="presentation"  style={{height: "1.5rem", width: "1.5rem"}}>
      <path d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

const ConfiguredDevicesSVG = () => {
  return(
    <svg viewBox="0 0 24 24" role="presentation"  style={{height: "1.5rem", width: "1.5rem"}}>
      <path d="M2.61,21L1.61,19.27L11,13.85V3H13V13.85L22.39,19.27L21.39,21L12,15.58L2.61,21Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

const SmartNetworkSVG = () => {
  return(
    <svg viewBox="0 0 24 24" role="presentation"  style={{height: "1.5rem", width: "1.5rem"}}>
      <path d="M12,21L15.6,16.2C14.6,15.45 13.35,15 12,15C10.65,15 9.4,15.45 8.4,16.2L12,21M12,3C7.95,3 4.21,4.34 1.2,6.6L3,9C5.5,7.12 8.62,6 12,6C15.38,6 18.5,7.12 21,9L22.8,6.6C19.79,4.34 16.05,3 12,3M12,9C9.3,9 6.81,9.89 4.8,11.4L6.6,13.8C8.1,12.67 9.97,12 12,12C14.03,12 15.9,12.67 17.4,13.8L19.2,11.4C17.19,9.89 14.7,9 12,9Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

const HostProcessSVG = () => {
  return(
    <svg viewBox="0 0 24 24" role="presentation"  style={{height: "1.5rem", width: "1.5rem"}}>
      <path d="M12,2L1,21H23M12,6L19.53,19H4.47"
        fill="currentColor"
      ></path>
    </svg>
  )
}

const SystemStatesSVG = () => {
  return(
    <svg viewBox="0 0 24 24" role="presentation"  style={{height: "1.5rem", width: "1.5rem"}}>
      <path d="M22.61,19L13.53,9.91C14.46,7.57 14,4.81 12.09,2.91C9.79,0.61 6.21,0.4 3.66,2.26L7.5,6.11L6.08,7.5L2.25,3.69C0.39,6.23 0.6,9.82 2.9,12.11C4.76,13.97 7.47,14.46 9.79,13.59L18.9,22.7C19.29,23.09 19.92,23.09 20.31,22.7L22.61,20.4C23,20 23,19.39 22.61,19M19.61,20.59L10.15,11.13C9.54,11.58 8.86,11.85 8.15,11.95C6.79,12.15 5.36,11.74 4.32,10.7C3.37,9.76 2.93,8.5 3,7.26L6.09,10.35L10.33,6.11L7.24,3C8.5,2.95 9.73,3.39 10.68,4.33C11.76,5.41 12.17,6.9 11.92,8.29C11.8,9 11.5,9.66 11.04,10.25L20.5,19.7L19.61,20.59Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

const ServicesSVG = () => {
  return(
    <svg viewBox="0 0 24 24" role="presentation"  style={{height: "1.5rem", width: "1.5rem"}}>
      <path d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M12,10A2,2 0 0,0 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12A2,2 0 0,0 12,10M10,22C9.75,22 9.54,21.82 9.5,21.58L9.13,18.93C8.5,18.68 7.96,18.34 7.44,17.94L4.95,18.95C4.73,19.03 4.46,18.95 4.34,18.73L2.34,15.27C2.21,15.05 2.27,14.78 2.46,14.63L4.57,12.97L4.5,12L4.57,11L2.46,9.37C2.27,9.22 2.21,8.95 2.34,8.73L4.34,5.27C4.46,5.05 4.73,4.96 4.95,5.05L7.44,6.05C7.96,5.66 8.5,5.32 9.13,5.07L9.5,2.42C9.54,2.18 9.75,2 10,2H14C14.25,2 14.46,2.18 14.5,2.42L14.87,5.07C15.5,5.32 16.04,5.66 16.56,6.05L19.05,5.05C19.27,4.96 19.54,5.05 19.66,5.27L21.66,8.73C21.79,8.95 21.73,9.22 21.54,9.37L19.43,11L19.5,12L19.43,13L21.54,14.63C21.73,14.78 21.79,15.05 21.66,15.27L19.66,18.73C19.54,18.95 19.27,19.04 19.05,18.95L16.56,17.95C16.04,18.34 15.5,18.68 14.87,18.93L14.5,21.58C14.46,21.82 14.25,22 14,22H10M11.25,4L10.88,6.61C9.68,6.86 8.62,7.5 7.85,8.39L5.44,7.35L4.69,8.65L6.8,10.2C6.4,11.37 6.4,12.64 6.8,13.8L4.68,15.36L5.43,16.66L7.86,15.62C8.63,16.5 9.68,17.14 10.87,17.38L11.24,20H12.76L13.13,17.39C14.32,17.14 15.37,16.5 16.14,15.62L18.57,16.66L19.32,15.36L17.2,13.81C17.6,12.64 17.6,11.37 17.2,10.2L19.31,8.65L18.56,7.35L16.15,8.39C15.38,7.5 14.32,6.86 13.12,6.62L12.75,4H11.25Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

const LogsSVG = () => {
  return(
    <svg viewBox="0 0 24 24" role="presentation"  style={{height: "1.5rem", width: "1.5rem"}}>
      <path d="M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3"
        fill="currentColor"
      ></path>
    </svg>
  )
}

const JobsInfoSVG = () => {
  return(
    <svg viewBox="0 0 24 24" role="presentation"  style={{height: "1.5rem", width: "1.5rem"}}>
      <path d="M20,18H4V8H20M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export const SideNav = (props:any) => {
  const navigate = useNavigate();
  const { clearController } = useContext(ControllerContext)
  const [selected, setSelected] = useState<String>('homeStatus');
  props.handleSelect(selected); //Locked sidenav to homeStatus
  const handleSelect = (eventKey:String) => {
    setSelected(eventKey);
  };

  const handleGoBack = () => {
    navigate('/Listing');
  }
  return(
    <AsideContainer>
      <AsideTopDiv onClick={clearController}>
        <AsideTopDivButton onClick={handleGoBack}>
          <svg viewBox="0 0 24 24" role="presentation"  style={{height: "1.5rem", width: "1.5rem"}}>
          <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
            fill="currentColor"
          ></path>
          </svg>
          <AsideTopDivSpan>Back</AsideTopDivSpan>
        </AsideTopDivButton>
      </AsideTopDiv>
      <div style={{boxSizing: "inherit"}}>
        <nav>
          <AsideUL>
            <AsideLI
              style={selected === 'homeStatus' ? {color: "#A89968", background: "rgb(237, 234, 229)"}: {}} 
              onClick={() => handleSelect('homeStatus')}
            >
              <AsideA >
                <HomeSVG/>
                <AsideASpan>Home Status</AsideASpan>
              </AsideA>
            </AsideLI>
            <AsideLI
              style={selected === 'configuration' ? {color: "#A89968", background: "rgb(237, 234, 229)"}: {}} 
              onClick={() => handleSelect('configuration')}
            >
              <AsideA >
                <ConfigurationSVG/>
                <AsideASpan>Configuration</AsideASpan>
              </AsideA>
            </AsideLI >
            <AsideLI
              style={selected === 'softwareUpdate' ? {color: "#A89968", background: "rgb(237, 234, 229)"}: {}}
              onClick={() => handleSelect('softwareUpdate')}
              >
              <AsideA >
                <SoftwareUpdateSVG/>
                <AsideASpan>Software Update</AsideASpan>
              </AsideA>
            </AsideLI>
            <AsideLI
              style={selected === 'subscriptions' ? {color: "#A89968", background: "rgb(237, 234, 229)"}: {}}
              onClick={() => handleSelect('subscriptions')}
              >
              <AsideA >
                <SubscriptionsSVG/>
                <AsideASpan>Subscriptions</AsideASpan>
              </AsideA>
            </AsideLI>
            <AsideLI
              style={selected === 'licensing' ? {color: "#A89968", background: "rgb(237, 234, 229)"}: {}}
              onClick={() => handleSelect('licensing')}
              >
              <AsideA >
                <LicensingSVG/>
                <AsideASpan>Licensing</AsideASpan>
              </AsideA>
            </AsideLI>
            <AsideLI
              style={selected === 'configuredDevices' ? {color: "#A89968", background: "rgb(237, 234, 229)"}: {}}
              onClick={() => handleSelect('configuredDevices')}
              >
              <AsideA >
                <ConfiguredDevicesSVG/>
                <AsideASpan>Configured Devices</AsideASpan>
              </AsideA>
            </AsideLI>
            <AsideLI
              style={selected === 'smartNetwork' ? {color: "#A89968", background: "rgb(237, 234, 229)"}: {}}
              onClick={() => handleSelect('smartNetwork')}
              >
              <AsideA >
                <SmartNetworkSVG/>
                <AsideASpan>Smart Network</AsideASpan>
              </AsideA>
            </AsideLI>
            <AsideLI
              style={selected === 'hostProcess' ? {color: "#A89968", background: "rgb(237, 234, 229)"}: {}}
              onClick={() => handleSelect('hostProcess')}
              >
              <AsideA >
                <HostProcessSVG/>
                <AsideASpan>Host Process</AsideASpan>
              </AsideA>
            </AsideLI>
            <AsideLI
             style={selected === 'systemStates' ? {color: "#A89968", background: "rgb(237, 234, 229)"}: {}}
             onClick={() => handleSelect('systemStates')}
             >
              <AsideA >
                <SystemStatesSVG/>
                <AsideASpan>System States</AsideASpan>
              </AsideA>
            </AsideLI>
            <AsideLI
             style={selected === 'services' ? {color: "#A89968", background: "rgb(237, 234, 229)"}: {}}
             onClick={() => handleSelect('services')}
             >
              <AsideA >
                <ServicesSVG/>
                <AsideASpan>Services</AsideASpan>
              </AsideA>
            </AsideLI>
            <AsideLI
             style={selected === 'logs' ? {color: "#A89968", background: "rgb(237, 234, 229)"}: {}}
             onClick={() => handleSelect('logs')}
             >
              <AsideA >
                <LogsSVG/>
                <AsideASpan>Logs</AsideASpan>
              </AsideA>
            </AsideLI>
            <AsideLI
             style={selected === 'jobsInfo' ? {color: "#A89968", background: "rgb(237, 234, 229)"}: {}}
             onClick={() => handleSelect('jobsInfo')}
             >
              <AsideA >
                <JobsInfoSVG/>
                <AsideASpan>Jobs Info</AsideASpan>
              </AsideA>
            </AsideLI>
          </AsideUL>
        </nav>
      </div>
    </AsideContainer>
  )
}