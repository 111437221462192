
import {
  Container,
  Span,
  Before,
  DivOne,
  DivTwo,
  DivThree,
  DivFour,
  DivFourDiv,
  DivFourDivImgDiv,
  DivFourDivImg,
} from "./styles";

import miniProcessadora from "../../assets/miniprocess.png";

const FolderSvg = () => {
  return (
    <svg viewBox="0 0 24 24" style={{width: "1.05rem", height: "1.05rem", marginRight: "10px"}}>
      <path d="M20,18H4V8H20M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

const CheckSvg = () => {
  return (
    <svg viewBox="0 0 24 24" style={{width: "1.125rem", height: "1.125rem"}}>
      <path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" 
        fill="rgb(35, 197, 20)"
      ></path>
    </svg>
  )
}

export const TopPath = () => {
  return (
    <Container>
      <DivOne>
        <Span> Audiogene Americas LLC </Span>
      </DivOne>
      <DivTwo>
        <Before> / </Before>
        <Span> SP - Sao Paulo </Span>
      </DivTwo>
      <DivThree>
        <Before> / </Before>
        <FolderSvg/>
        <Span> Roberto Adams </Span>
      </DivThree>
      <DivFour>
        <Before> / </Before>
        <div style={{boxSizing: "inherit" }}>
          <CheckSvg/>
        </div>
        <DivFourDiv>
          <DivFourDivImgDiv>
            <DivFourDivImg src={miniProcessadora} alt="mini processadora"/>
          </DivFourDivImgDiv>
        </DivFourDiv>
        <Span> Roberto System </Span>
      </DivFour>
    </Container>
  )
}