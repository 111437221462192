import styled from 'styled-components'

export const SectionOneContent = styled.div`
  @media screen and (min-width: 768px) {
    margin-bottom: 16px;
  }

    width: 100%;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.01) 10px 10px 10px;
    animation: 0.2s ease-out 0s 1 normal none running krkVAV;
    box-sizing: inherit;
`;

export const SectionOneTitle = styled.h2`
  font-size: 32px;
  line-height: 32px;
  color: rgb(0, 0, 0);
  margin-bottom: 8px;
  color: rgb(56, 46, 43);
`;

export const SectionOneSubtitle = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  color: rgb(56, 46, 43);
`;

export const LicensingDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 0px 40px;
`;

export const AppliedLicensesDiv = styled.div`
  display: flex;
  margin: 0px 0px 16px;
`;

export const AppliedLicensesText = styled.p`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px 16px;
  background: rgb(242, 242, 242);
  border-radius: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin: 0px 0px 0px 16px;
`;
