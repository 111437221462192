import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Search from '../../components/Search'
import Lists from '../../components/Lists'
import { MainContainer, MainTitle, OuterContainer, RootDiv, SearchDiv, TitleDiv } from './styles'

export default function Templates() {
  return (
    <>
      <RootDiv>
        <Header login={false} />
        <OuterContainer>
          <MainContainer>
            <TitleDiv>
              <MainTitle>Templates</MainTitle>
            </TitleDiv>
            <SearchDiv>
              <Search text={'Search by template name or user name...'}/>
            </SearchDiv>
            <Lists listType='configuration' />
          </MainContainer>
        </OuterContainer>
        <Footer />
      </RootDiv>
    </>
  )
}