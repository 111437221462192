import { useEffect, useState } from 'react';
import { TailSpin  } from 'react-loading-icons'
//imports to post
import axios from 'axios';
import FormData from 'form-data';

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';
import InputGroup from 'react-bootstrap/InputGroup';
import Header from '../../components/Header';

import { BiTrashAlt } from "react-icons/bi";
import { BsCloudCheck } from "react-icons/bs";
import { AiOutlineCloudUpload } from "react-icons/ai";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function MultipleUpload() {
  const [progress, setProgress] = useState(0);
  const [files, setFiles] = useState<File[]>([]);
  const [headers, setHeaders] = useState<any[]>([]);
  const [serialNumber, setSerialNumber] = useState("");
  const [inputsForms, setInputsForms] = useState<any[]>([]);
  const [fileNameLenghtInPixels, setFileNameLenghtInPixels] = useState(0);
  const [done, setDone] = useState(false);
  const [spinner, setSpinner] = useState(0);

  const local = false;

  const serverIP = local ? 'http://localhost:3001/api' : 'https://onea.company/api';
  //serial number 8A36024820203940
  const [uploaded, setUploaded] = useState(false);
  const [readingFile, setReadingFile] = useState<any[]>([]);
  const [confirmedFiles, setConfirmedFiles] = useState<String[]>([]);
  const [getting, setGetting] = useState(false);

  const [connected, setConnected] = useState(false);

  const HandleConnect = () => {
    if(serialNumber){
      setConnected(true);
    }
  }

  const checkInputs = () => {
    if(!serialNumber){
      alert("Serial number is required");
      return false;
    }
    if(!files || files.length === 0){
      alert("Files are required");
      return false;
    }
    if(!headers || headers.length === 0){
      alert("Headers are required");
      return false;
    }
    if(files.length !== headers.length){
      alert("Files and headers must have the same length");
      return false;
    }
    return true;
  }

  const HandleSubmit = async () => {
    setDone(false);
    setProgress(0);
    if(!checkInputs()){
      return;
    }
    const data = new FormData();
    data.append('serialNumber', serialNumber);
    for(let i = 0; i < headers.length; i++){
      data.append('headers', headers[i]);
    }
    // let filesNames = [];
    for(let i = 0; i < files.length; i++){
      data.append('file number' + i, files[i], files[i].name);
      // filesNames.push(files[i].name);
    }
    // setReadingFile(filesNames);
    if(data){
    try {
        await axios.post(serverIP, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      } catch (error) {
        setUploaded(false);
      } finally {
        setProgress(100);
        setUploaded(true);
      }
    }
  }
  
  const HandleInputFiles = async (e:any) => {
    if(files){
      setFiles([]);
      setInputsForms([]);
      setFileNameLenghtInPixels(0);
      setUploaded(false);
      setDone(false);
      setReadingFile([]);
    }
    try{
      const tempFiles = e.target.files;
      //convert object to array
      let tempFilesArray = [];
      let biggestFileName = 0;
      for(let i = 0; i < tempFiles.length; i++){
        // if(tempFiles[i].size > 100000){
        //   alert("Arquivo " + tempFiles[i].name + " é grande mais, o arquivo deve ter menos 100KB");
        //   return;
        // }
        tempFilesArray.push(tempFiles[i]);
        if(tempFiles[i].name.length > biggestFileName){
          biggestFileName = tempFiles[i].name.length;
        }
      }
      setFileNameLenghtInPixels(biggestFileName * 10);
      setFiles(tempFilesArray);
    }
    catch(error){
      console.error(error);
    }
  }

  const HandleInputsHeader = (value:any, index:any) => {
    if(value.length > 14){
      alert("Header must be less than 15 characters");
      return;
    }
    let tempHeader = headers;
    tempHeader[index] = value;
    setHeaders(tempHeader);
  }

  const HandleDeleteFile = (index:any) => {
    let tempFiles = [];
    tempFiles = files;
    if(tempFiles && !uploaded){
      tempFiles.splice(index, 1);
      setInputsForms(tempFiles);
    } else {
      console.log("No files to delete");
    }
  }
  
  useEffect(() => {
    if(files && files.length > 0){
      let tempInputsForms:any = [];
      for(let i = 0; i < files.length; i++){
        tempInputsForms.push(i);
      }      
      setInputsForms(tempInputsForms);
    }
  }, [files]);


  const checkFilesRead = async () => {
    if (serialNumber && uploaded && !done && connected) {
      await axios.get(serverIP + '/' + serialNumber + '/readingfile')
        .then((response) => {
          console.log("response.data", response.data);
          setReadingFile(response.data);
        })
        .catch((error) => {
          console.log("error: ", error);
        })
      setGetting(true);
      checkConfirmedFiles();
    }
  }


  const [data, setData] = useState();

  const [processadoraName, setProcessadoraName] = useState("");
  const [processadoraVersion, setProcessadoraVersion] = useState("");


  useEffect(() => {
    if(data){
      // exemple: 004D31360000000000000004000200
      // data is a string of hexa values
      // first hexa is the id of the data
      // second comes 10 haxes that represents the name of the processador in ascii
      // third comes the version of the processador in the last 4 haxes
      
      const dataString:string = data;
      // first check if the id is 00
      if(dataString.substring(0, 2) === "00"){
        // get the name
        let name = "";
        for(let i = 2; i < 22; i+=2){
          name += String.fromCharCode(parseInt(dataString.substring(i, i+2), 16));
        }
        setProcessadoraName(name);
        // get the version
        let version = "";
        for(let i = 22; i < 30; i+=2){
          // add dots between the version numbers
          if(i !== 22){
            version += ".";
          }
          version += parseInt(dataString.substring(i, i+2), 16);
        }
        setProcessadoraVersion(version);
      }
    }
  }, [data]);




  const checkData = async () => {
    if ( serialNumber && connected ) {
      await axios.get(serverIP + '/' + serialNumber + '/data')
        .then((response) => {
          if(response.data){
            if(response.data.length > 0){
              setData(response.data);
            }
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        })
    }
  }
  
  useEffect(() => {
    const interval = setInterval(() => {
      if(serialNumber && connected){
        checkData();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [serialNumber, connected]);

  useEffect(() => {
    if(done){
      return;
    }
    const interval = setInterval(() => {
      checkFilesRead();
    }, 1000);
    return () => clearInterval(interval);
  }, [connected, uploaded, done, serialNumber]);

  const [lastReadTime, setLastReadTime] = useState(0);
  const [pollTime , setPollTime] = useState(0);
  const [countDown, setCountDown] = useState(0);

  const checkLastRead = async () => {
    if(connected && serialNumber){
      await axios.get(serverIP + '/' + serialNumber + '/polltime')
      .then((response) => {
        if(response.data !== lastReadTime){
          setLastReadTime(response.data);
          if(response.data - lastReadTime < 100000 && lastReadTime !== 0){
            setPollTime((response.data - lastReadTime) / 1000);
            setCountDown(0);
            setSpinner(0);
          }
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      })
    }
  }

  const checkConfirmedFiles = async () => {
    if(connected && serialNumber){
      await axios.get(serverIP + '/' + serialNumber + '/confirmed')
      .then((response) => {
        if(response.data){
          setConfirmedFiles(response.data)
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      })
    }
  }

  useEffect(() => {
    if(connected && serialNumber){
      const interval = setInterval(() => {
        checkLastRead();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [connected, serialNumber, lastReadTime, pollTime, countDown, spinner]);


  useEffect(() => {

    if(countDown === 0 && pollTime > 0){
      setCountDown(pollTime);
    }

    if(connected && pollTime > 0){
      const intervalSpinner = setInterval(() => {
        if(countDown > 0){
          setCountDown(countDown - 1);
        }else{
          setCountDown(0);
        }
        if(spinner < 100){
          setSpinner(spinner + (100 / pollTime));
        }else{
          setSpinner(0);
        }
      }, 1000);
      return () => clearInterval(intervalSpinner);
    }
  }, [connected, spinner, pollTime, countDown]);

  useEffect(() => {
    if(files && files.length > 0 && readingFile && readingFile.length === 0 && uploaded && !done && getting){
      if(confirmedFiles.length === files.length){
      setDone(true);
      setGetting(false);
      }
    }
  }, [readingFile, uploaded, done]);


  // useEffect(() => {
  //   console.log("confirmedFiles: ", confirmedFiles);
  //   console.log("readingFile: ", readingFile);
  //   },[confirmedFiles, readingFile]);

  return (
    <>
      {/* <Header login={false}/> */}
      <Container style={{ marginTop: 10 }}>
      <h3> Cloud Update </h3>
      <div style={{flexDirection: 'row', display: 'flex'}}>
        Data: {data}
      </div>
      <div style={{flexDirection: 'row', display: 'flex'}}>
        Name: {processadoraName}
      </div>
      <div style={{flexDirection: 'row', display: 'flex'}}>
        Version: {processadoraVersion}
      </div>
        <Form style={{ marginTop: 10 }}>       
          <Form.Group controlId="formBasicEmail">
            <Form.Label><h6>Serial Number</h6></Form.Label>
            <Form.Control type="text" placeholder="Enter serial number" onChange={(e) => setSerialNumber(e.target.value)} />
          </Form.Group>
        </Form>
        <div style={{ height: 20, width: 1 }}></div>
        <Button variant="warning" onClick={HandleConnect} type="button">
          Conectar
        </Button>
      </Container>
        {connected && (
          <>
            <Container>
              <div style={{height: 20, width: 1}}></div>
              <Form>
                <Form.Group controlId="files">
                  <Form.Control type="file" multiple placeholder="Enter file" onChange={(e) => HandleInputFiles(e)} />
                </Form.Group>
              </Form>
              {inputsForms && inputsForms.map((file, index) => (
                <>
                  <div style={{height: 20, width: 1}}></div>
                  <InputGroup key={index}>
                    <InputGroup.Text 
                      id="basic-addon3"
                      style={{width: fileNameLenghtInPixels}}
                    >
                      {files[index].name}
                    </InputGroup.Text>
                    <Form.Control
                      placeholder='Custom header'
                      onChange={(e) => HandleInputsHeader(e.target.value, index)}
                    />
                    {uploaded && files && files.length > 0 &&
                      <InputGroup.Text id="basic-addon2">
                        {readingFile.includes(files[index].name) ?
                          (
                            <TailSpin
                              stroke="#ffc107"
                              fill="#ffc107"
                              width={15}
                              height={15}
                            />
                          )
                          : confirmedFiles.includes(headers[index]) ?
                            (
                              <BsCloudCheck 
                                stroke="#198754"
                                fill="#198754"
                              />
                            ) : (
                              <AiOutlineCloudUpload 
                                stroke="#06bcee"
                                fill="#06bcee"/>
                            )
                        }
                      </InputGroup.Text>
                    }
                    <Button variant="outline-secondary" onClick={(e) => HandleDeleteFile(index)}><BiTrashAlt /></Button>
                  </InputGroup>
                </>
              ))}
            </Container>
            <Container>
              <div style={{height: 20, width: 1}}></div>
              <ProgressBar variant="success" now={progress} />
              <div style={{height: 20, width: 1}}></div>
              <Button variant="success" onClick={HandleSubmit} type="button">
                Enviar
              </Button>
              
              {pollTime > 0 ? (
                <>
                  <Box sx={{ position: 'absolute', display: 'inline-flex', marginLeft: 5 }}>
                    <CircularProgress variant="determinate" value={spinner} />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary" 
                      >{`${Math.round(countDown)}s`}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ position: 'absolute', display: 'inline-flex', marginLeft: 15 , marginTop: 0.5}}>
                    Poll time (ms): {pollTime}
                  </Box>
                </>
              ) : (
                <Box sx={{ position: 'absolute', display: 'inline-flex', marginLeft: 5 , marginTop: 0.5}}>
                  <TailSpin
                    stroke="#ffc107"
                    fill="#ffc107"
                    width={30}
                    height={30}
                  />
                  <div style={{marginLeft: 20}}>
                    Syncing
                  </div>
                </Box>
              )}
            </Container>
          </>
        )}
    </>
  );
}

export default MultipleUpload;