import styled from 'styled-components';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

export const StyledFormControl = styled(Form.Control)`
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* set a new box shadow */
    border-color: #ced4da; /* set the default border color */

    &:focus {
        border-color: #A89968 !important; /* set the border color when in focus */
        box-shadow: 0 0 0 0.25rem rgba(168, 153, 104, 0.25) !important; /* set a new box shadow when in focus */
    }

    &:hover {
        border-color: #A89968 !important; /* set the border color when in focus */
    }
`;

export const StyledFormGroup = styled(Form.Group)`
    margin-bottom: 20px;
    border-color: rgb(165, 165, 165);
    margin: 0px 0px 12px;
    height: 40px;
    font-size: 14px;
    font-family: "Gotham", "Roboto", sans-serif;
    line-height: 24px;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    outline: none;
    width: 100%;
    caret-color: rgb(168, 153, 104);
`;

export const StyledInputGroup = styled(InputGroup)`
    margin-bottom: 20px;
    border-color: rgb(165, 165, 165);
    margin: 0px 0px 12px;
    height: 40px;
    font-size: 14px;
    font-family: "Gotham", "Roboto", sans-serif;
    line-height: 24px;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    outline: none;
    width: 100%;
    caret-color: rgb(168, 153, 104);
`;

export const BackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
`;

export const CustomButton = styled.button`
    appearance: button;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-weight: bold;
    width: fit-content;
    padding: 12px 32px;
    font-size: 16px;
    line-height: 18px;
    font-family: "Gotham", "Roboto", sans-serif;
    color: rgb(255, 255, 255);
    border: none;
    background-color: rgb(168, 153, 104);
    border-radius: 8px;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease 0s;
    user-select: none;
    white-space: nowrap;

    &:select {
        text-transform: none;
    }
`;




export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 150px auto;
    background-color: rgba(255, 255, 255, 1);
    padding: 0px;
    color: rgb(56, 46, 43);
    border-radius: 8px;
    backdrop-filter: blur(20px);
    animation: 0.2s ease 0s 1 normal none running krkVAV;
`;

export const LoginContainer = styled.div`
    margin-bottom: 32px;
    box-sizing: inherit;
    display: block;
`;

export const FormContainer = styled.div`
    max-width: 471px;
    padding: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px auto;
    box-sizing: inherit;
`;

export const FormInput = styled.input`
    margin-bottom: 16px;
    margin: 0px 0px 12px;
    height: 40px;
    font-size: 14px;
    font-family: "Gotham", "Roboto", sans-serif;
    line-height: 24px;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 8px;
    outline: none;
    width: 100%;
    caret-color: rgb(42, 40, 36);

    &:focus {
        border: 1px solid rgb(42, 40, 36);
    }

    &::placeholder {
        color: rgb(42, 40, 36);
    }
`;

export const LoginTitle = styled.h2`
    margin-top: 36px;
    margin: 36px 0px 24px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    box-sizing: inherit;
    display: block;
    font-family: "Gotham", "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
`;

export const LoginSubTitle = styled.div`
    margin: 0px auto 24px !important;
    width: 80%;
    line-height: 22px;
    font-size: 18px;
    color: rgb(95, 95, 95);
    text-size-adjust: 100%;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    text-align: center;
`;

export const BottomTexts = styled.div`
    margin-top: 24px;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    color: rgb(95, 95, 95);
    box-sizing: inherit;
    display: block;
`;

export const ButtonContainer = styled.div`
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: inherit;
`;

export const PassContainer = styled.div`
    text-align: right;
    margin-bottom: 24px;
    box-sizing: inherit;
`;

export const Hyperlink = styled.a`
    color: #A89968;
    font-weight: 700;
    text-decoration: none;
    pointer-events: auto;
    cursor: pointer;
    font-size: 16px;
    font-family: "Gotham", "Roboto", sans-serif;
    line-height: 1.5;
    &:hover {
        color: #000;
    }
`;

export const AText = styled.a`
    color: #A89968;
    text-decoration: none;
    pointer-events: auto;
    cursor: pointer;
    background-color: transparent;
    font-size: 16px;
    line-height: 1.5;
    font-family: "Gotham", "Roboto", sans-serif;
    &:hover {
        color: #000;
    }
`;
