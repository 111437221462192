import styled from 'styled-components';


export const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.01) 10px 10px 10px;
    animation: 0.2s ease-out 0s 1 normal none running krkVAV;
    box-sizing: inherit;
    height: fit-content;
`;


export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: 0.2s ease-out 0s 1 normal none running krkVAV;
  flex-basis: 50%;
  box-shadow: none;
  padding: 16px 24px 24px;
  justify-content: space-between;
`;

export const ContentHouseImage = styled.div`
  display: flex;
  flex-basis: 50%;
  -webkit-box-flex: 1;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 16px 16px 24px;
  height: fit-content;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 12px;
    box-sizing: inherit;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: inherit;
`;

export const HeaderTitleImgDiv = styled.div`
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: inherit;
  margin: 0px 8px 0px 0px;
`;

export const HeaderTitleName = styled.div`
  @media screen and (min-width: 768px) {
    display: block;
    word-break: break-word;
  }
  
  display: none;
  font-weight: normal;
  box-sizing: inherit;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: rgb(56, 46, 43);
`;

export const HeaderDescription = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: inherit;
`;

export const HeaderDescriptionDiv = styled.div`
  display: flex;
  height: 40px;
  padding: 13px 16px;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(56, 46, 43);
  border-radius: 8px;  
  font-size: 14px;
  line-height: 16px;
  box-sizing: inherit;
`;

export const HeaderDescriptionP = styled.p`
  font-size: 18px;
  color: rgb(255, 95, 0);
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
  padding: 0px 6px;
  margin: 18px 0px 16px;
`;

export const HeaderDescriptionButton = styled.button`
  padding: 0px;
  color: rgb(255, 95, 0);
  font-weight: bold;
  border: none;
  background-color: transparent;
  cursor: pointer;
  appearance: button;
  text-transform: none;
  overflow: visible;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0px;
  box-sizing: inherit;
`;

export const ContentContent = styled.div`
  @media screen and (min-width: 768px) {
    gap: 32px 0px;
    flex-direction: row;
  }
  
  display: flex;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  box-sizing: inherit;
`;

export const RoehnEssencialsDiv = styled.div`
  @media screen and (min-width: 1025px) {
    flex-basis: 32%;
  }

  @media screen and (min-width: 768px) {
    // flex-basis: 100%;
  }

  display: flex;
  flex-direction: row;
  box-sizing: inherit;
  align-items: center;
  vertical-align: middle;
`;

export const RoehnEssencialsTitle = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  flex: 1 1 0%;
  white-space: nowrap;
`;

export const ManageStatusDiv = styled.div`
  width: 200px;
  border-radius: 8px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: inherit;
  gap: 12px;
`;

export const ManageStatusContent = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 100%;
  box-sizing: inherit;
`;

export const ManageStatusDescription = styled.div`
  display: flex;
  align-items: flex-end;
  color: rgb(35, 197, 20);
  font-weight: 600;
  box-sizing: inherit;
`;

export const ChecksDiv = styled.div`
  @media screen and (min-width: 1025px) {
    flex-basis: unset;
    min-width: 170px;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    // flex-basis: 50%;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: flex-start;
  }

  gap: 8px;
  flex-direction: column;
  font-size: 12px;
  box-sizing: inherit;
`;

export const SectionOneSubtitle = styled.h2`
  font-size: 32px;
  margin: 16px 0px;
  font-weight: 600;
  color: rgb(56, 46, 43);
`;

export const SubscriptionHistoryDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  width: 1080px;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding: 40px;
`;

export const SubscriptionHistoryTitle = styled.h1`
    font-size: 32px;
    line-height: 32px;
    color: rgb(105, 96, 87);
    margin: 0 auto;
    vertical-align: middle;
    text-align: center;
    margin-top: 28px;
    margin-bottom: 16px;
`;

export const SubscriptionHistoryInfoText = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #A5A5A5;
  margin: 0 auto;
  vertical-align: middle;
`;

export const DividerDiv = styled.div`
    width: 1px;
    height: 160px;
    margin: 16px 0;
    color: #F2F2F2;
    background-color: #F2F2F2;
`;

export const SubscriptionHouseImage = styled.img`
  width: 312px;
  height: 168px;
`;

export const ExpirationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;


export const ExpirationText = styled.p`
  margin-bottom: 0px;
  line-height: 20px;
  font-size: 18px;
  font-weight: 600;
  text-align: right;
  color: rgb(128, 126, 126);
  align-self: flex-end;
`;

export const ManageContent = styled.div`
  @media screen and (min-width: 768px) {
    gap: 32px 0px;
    flex-direction: row;
  }
  
  display: flex;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
  box-sizing: inherit;
`;

export const SubscriptionAndEssentialsDiv = styled.div`
  display: flex;
  flex-direction: column;
`;