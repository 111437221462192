import styled from 'styled-components'

export const TopGroupDiv = styled.div`
  display: block;
  width: 100%;
  box-sizing: inherit;

`;

export const TopGroupDivSearch = styled.div`
  position: relative;
  z-index: 1;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(242, 242, 242, 0.5) 0px 0px 0px 1px inset;
  border-radius: 8px;
  display: flex;
  padding: 8px 16px;
  transition: all 0.3s ease 0s;
  width: 100%;
`;

export const TopGroupDivSearchInput = styled.input`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  background-color: transparent;
  border: none;
  margin-left: 8px;
  width: 100%;
  padding: 0px;
  transition: all 0.3s ease 0s;
  outline: none;
  font-family: inherit;
  box-sizing: inherit;
`;