import React from 'react';

import {
  DivTwo,
  DivTwoDiv,
  DivTwoDivLabel,
  DivTwoDivInput,
  DivTwoDivInputSpan,
  DivTwoDivInputSpanTwo,
  OneButtonDivTwoDivInputSpan,
  MiddleButtonDivTwoDivInputSpan,
  OneButtonDivTwoDiv,
  OneButtonDivTwoDivLabel,
  TwoButtonsDivTwoDiv,
  TwoButtonsDivTwoDivLabel,
  TwoButtonsDivTwoDivInputSpanLeftButton,
  TwoButtonsDivTwoDivInputSpanRightButton
} from './styles';

interface Props {
  quantity: number;
  titles: string[];
  customStyles?: React.CSSProperties;
}

export default function Buttons(Props: Props) {
  return ( 
    <>
      {Props.quantity === 1 && (
        <>
        <OneButtonDivTwoDiv>
          <OneButtonDivTwoDivLabel id='label1'>
            <OneButtonDivTwoDivInputSpan>
              {Props.titles[0]}
            </OneButtonDivTwoDivInputSpan>
          </OneButtonDivTwoDivLabel>
        </OneButtonDivTwoDiv>
        </>
      )}
      {Props.quantity === 2 && (
        <TwoButtonsDivTwoDiv>
          <TwoButtonsDivTwoDivLabel id='label1'>
            <TwoButtonsDivTwoDivInputSpanLeftButton>
              {Props.titles[0]}
            </TwoButtonsDivTwoDivInputSpanLeftButton>
          </TwoButtonsDivTwoDivLabel>
          <TwoButtonsDivTwoDivLabel id='label2'>
            <TwoButtonsDivTwoDivInputSpanRightButton>
              {Props.titles[1]}
            </TwoButtonsDivTwoDivInputSpanRightButton>
          </TwoButtonsDivTwoDivLabel>
        </TwoButtonsDivTwoDiv>
      )}
      {Props.quantity === 3 && (
        <DivTwoDiv>
          <DivTwoDivLabel id='label1'>
            <DivTwoDivInput />
            <DivTwoDivInputSpan>
              {Props.titles[0]}
            </DivTwoDivInputSpan>
          </DivTwoDivLabel>
          <DivTwoDivLabel id='label2'>
            <DivTwoDivInput />
            <MiddleButtonDivTwoDivInputSpan>
              {Props.titles[1]}
            </MiddleButtonDivTwoDivInputSpan>
          </DivTwoDivLabel>
          <DivTwoDivLabel id='label3'>
            <DivTwoDivInput />
            <DivTwoDivInputSpanTwo>
              {Props.titles[2]}
            </DivTwoDivInputSpanTwo>
          </DivTwoDivLabel>
        </DivTwoDiv>
      )}
    </>
  );
}