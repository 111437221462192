import { AccordionHeaderItems, AvailableNumber, CustomAccordion, CustomAccordionBody, CustomAccordionHeader, CustomAccordionItem, SVGAndTitleDiv, SubTitle, TitlesDiv } from './styles';
import Lists from '../Lists';
interface DropdownProps {
  titles: string[];
  items: string[];
}

const DropdownSVG = () => (
  <svg
    viewBox="0 0 24 24"
    role="presentation"
    style={{ width: '24px', height: '24px' }}
  >
    <path
      d="M9 10A3.04 3.04 0 0 1 12 7A3.04 3.04 0 0 1 15 10A3.04 3.04 0 0 1 12 13A3.04 3.04 0 0 1 9 10M12 19L16 20V16.92A7.54 7.54 0 0 1 12 18A7.54 7.54 0 0 1 8 16.92V20M12 4A5.78 5.78 0 0 0 7.76 5.74A5.78 5.78 0 0 0 6 10A5.78 5.78 0 0 0 7.76 14.23A5.78 5.78 0 0 0 12 16A5.78 5.78 0 0 0 16.24 14.23A5.78 5.78 0 0 0 18 10A5.78 5.78 0 0 0 16.24 5.74A5.78 5.78 0 0 0 12 4M20 10A8.04 8.04 0 0 1 19.43 12.8A7.84 7.84 0 0 1 18 15.28V23L12 21L6 23V15.28A7.9 7.9 0 0 1 4 10A7.68 7.68 0 0 1 6.33 4.36A7.73 7.73 0 0 1 12 2A7.73 7.73 0 0 1 17.67 4.36A7.68 7.68 0 0 1 20 10Z"
      style={{ fill: 'currentcolor' }}>
    </path>
  </svg>
);

export default function DropdownList() {
  return (
    <CustomAccordion>
      <CustomAccordionItem eventKey="0">
        <CustomAccordionHeader>
          <AccordionHeaderItems>
          <SVGAndTitleDiv>
            <DropdownSVG />
            <TitlesDiv>
              Roehn Essencials 1 Year Subscription (Smart)
              <SubTitle>SSL-SMRTE-1YR</SubTitle>
            </TitlesDiv>
          </SVGAndTitleDiv>
          <AvailableNumber>8 available</AvailableNumber>
          </AccordionHeaderItems>
        </CustomAccordionHeader>
        <CustomAccordionBody>
          <Lists listType='availableLicenses' />
        </CustomAccordionBody>
      </CustomAccordionItem>
    </CustomAccordion>
  );
}
