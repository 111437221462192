import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { HashRouter } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';

import Footer from './components/Footer';

const element = (
  <HashRouter>
  {/* <BrowserRouter> */}
    <div
      style={{
        position: 'relative',
        minHeight: '100vh',
        display: 'flex',
        WebkitBoxPack: 'justify',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: 'rgb(248,248,248)',
      }}
    >
      <App/>
      <Footer/>
    </div>
  {/* </BrowserRouter> */}
  </HashRouter>
);

const container = document.getElementById('root');

ReactDOM.render(element, container);