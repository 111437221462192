import { SectionOneContent, SectionOneTitle, SectionOneSubtitle, SmartNetworkDiv, AppliedLicensesDiv, AppliedLicensesText } from './styles'
import Lists from '../../Lists';
import Buttons from '../../Buttons';
import DropdownList from '../../DropdownList';

export default function SmartNetwork() {
  return (
    <SectionOneContent>
      <SmartNetworkDiv>
        <SectionOneTitle>Smart Network</SectionOneTitle>
        <Buttons quantity={1} titles={['Go To Mist Cloud']} />
      </SmartNetworkDiv>
    </SectionOneContent>
  )
}