import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Search from '../../components/Search'
import Lists from '../../components/Lists'
import { MainContainer, MainTitle, OuterContainer, RootDiv, SearchDiv, TitleDiv } from './styles'
import Buttons from '../../components/Buttons'

export default function Profiles() {
  return (
    <>
      <RootDiv>
        <Header login={false} />
        <OuterContainer>
          <MainContainer>
            <TitleDiv>
              <MainTitle>Profiles</MainTitle>
              <Buttons quantity={1} titles={['Upload Profile']} />
            </TitleDiv>
            <SearchDiv>
              <Search text={'Search by template name or user name...'}/>
            </SearchDiv>
            {/* <Lists listType='configuration' /> */}
          </MainContainer>
        </OuterContainer>
        <Footer />
      </RootDiv>
    </>
  )
}