import { useState } from 'react'

import { SectionOneContent, SectionOneTitle, SectionOneSubtitle, HomeConfigDiv, HomeConfigInfoText, HomeConfigFilesDiv } from './styles'
import Lists from '../../Lists';
import Buttons from '../../Buttons';

export default function Configuration() {
  return (
    <SectionOneContent>
      <SectionOneTitle>Configuration</SectionOneTitle>
      <SectionOneSubtitle>Active Configuration</SectionOneSubtitle>
      <Lists listType='configuration' />
      <HomeConfigFilesDiv>
        <SectionOneSubtitle>Home Config Files</SectionOneSubtitle>
        <Buttons quantity={1} titles={['Upload File']} />
      </HomeConfigFilesDiv>
      <HomeConfigDiv>
        <HomeConfigInfoText>No configuration files uploaded</HomeConfigInfoText>
      </HomeConfigDiv>
      <SectionOneSubtitle>Cloud Deployments</SectionOneSubtitle>
      <HomeConfigDiv>
        <HomeConfigInfoText>No deployment history available yet.</HomeConfigInfoText>
        <HomeConfigInfoText>If you deploy a configuration to Roberto System, you will see it here.</HomeConfigInfoText>
      </HomeConfigDiv>
    </SectionOneContent>
  )
}
