import styled from 'styled-components';

export const Main = styled.div`
  box-sizing: inherit;
  display: block;
`;


export const OuterDiv = styled.div`
  background-color: rgb(248, 248, 248);
  min-height: calc(100vh - 156px);
  padding-top: 40px;
  max-width: 1440px;
  margin: 0px auto;
  display: block;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased !important;
`;

export const MainContainer = styled.div`
  padding: 0px 40px;
  box-sizing: inherit;
  display: block;
`;

export const TopGroup = styled.div`
  overflow: hidden;
  display: flex;
  max-width: 100%;
  position: relative;
  z-index: 2;
  box-sizing: inherit;
`;

export const TopGroupNav = styled.nav`
  box-sizing: inherit;
  display: block;
`;

export const TopGroupNavDivOne = styled.div`
  @media screen and (min-width: 1025px) {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-right: 16px;
  }
  box-sizing: inherit;
`;

export const TopGroupNavDivOneItemOne = styled.div`
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 16px;
  box-sizing: inherit;
`;

export const TopGroupNavDivOneItemA = styled.a`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  transition: opacity 0.2s ease 0s;
  color: rgb(187, 168, 140);
  text-decoration: none;
  pointer-events: none;
  background-color: transparent;
  box-sizing: inherit;
`;

export const TopGroupNavDivOneItemTwo = styled.div`
  border-color: rgb(242, 242, 242);
  position: relative;
  box-sizing: border-box;
  animation: 0.3s ease 0s 1 normal none running fade-in;
  font-size: 14px;
  line-height: 16px;
  min-width: 160px;
  display: block;
  box-sizing: inherit;
`;

export const TopGroupNavDivOneItemTwoSpan = styled.span`
  z-index: 9999;
  border: 0;
  clip: rect(1px 1px 1px 1px);
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  box-sizing: inherit;
`;

export const TopGroupNavDivOneItemTwoDiv = styled.div`
  border-color: inherit;
  border-radius: 8px;
  min-height: 40px;
  box-shadow: none;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  outline: 0px !important;
  font-size: 14px;
  line-height: 16px;

  &:hover {
    border-color: rgb(187, 168, 140);
  }

  `;

export const TopGroupNavDivOneItemTwoDivOne = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: grid;
  flex: 1 1 0%;
  flex-wrap: wrap;
  padding: 2px 8px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
`;

export const TopGroupNavDivOneItemTwoDivOneDivOne = styled.div`
  color: rgb(128, 128, 128);
  grid-area: 1 / 1 / 2 / 3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
`;

export const TopGroupNavDivOneItemTwoDivOneDivTwo = styled.div`
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  visibility: visible;
  color: rgb(51, 51, 51);
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1 / 2 / 2 / 3;
  grid-template-columns: 0px min-content;
  box-sizing: border-box;
`;

export const TopGroupNavDivOneItemTwoDivOneDivTwoInput = styled.input`
  color: inherit;
  background: 0px center;
  opacity: 1;
  width: 100%;
  grid-area: 1 / 1 / auto / auto;
  font: inherit;
  min-width: 2px;
  border: 0px;
  margin: 0px;
  outline: 0px;
  padding: 0px;
  overflow: visible;
  box-sizing: inherit;
`;


export const TopGroupNavDivOneItemTwoDivTwo = styled.div`
  -webkit-box-align: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
`;

export const TopGroupNavDivOneItemTwoDivTwoSpan = styled.span`
  display: none;
  align-self: stretch;
  background-color: rgb(204, 204, 204);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
`;

export const TopGroupNavDivOneItemTwoDivTwoDiv = styled.div`
  color: rgb(105, 96, 87);
  display: flex;
  padding: 8px;
  transition: color 150ms ease 0s;
  box-sizing: border-box;
`;

export const TopGroupDiv = styled.div`
  display: block;
  width: 100%;
  box-sizing: inherit;

`;

export const TopGroupDivSearch = styled.div`
  position: relative;
  z-index: 1;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(242, 242, 242, 0.5) 0px 0px 0px 1px inset;
  border-radius: 8px;
  display: flex;
  padding: 8px 16px;
  transition: all 0.3s ease 0s;
  width: 100%;
`;

export const TopGroupDivSearchInput = styled.input`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  background-color: transparent;
  border: none;
  margin-left: 8px;
  width: 100%;
  padding: 0px;
  transition: all 0.3s ease 0s;
  outline: none;
  font-family: inherit;
  box-sizing: inherit;
`;